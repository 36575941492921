import React from "react";
import { Link } from "react-router-dom";

// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FormatQuote from "@material-ui/icons/FormatQuote";
import Star from "@material-ui/icons/Star";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Muted from "components/Typography/Muted.js";
import Warning from "components/Typography/Warning.js";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";

import dg2 from "assets/img/dg2.jpg";
import cardProfile1Square from "assets/img/faces/card-profile1-square.jpg";
import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";
import cardProfile4Square from "assets/img/faces/card-profile4-square.jpg";
import cardProfile6Square from "assets/img/faces/card-profile6-square.jpg";
import MentorAngie from "assets/img/faces/MentorAngie.jpeg";
import MentorJohn from "assets/img/faces/MentorJohn.jpeg";
import MentorBrad from "assets/img/faces/MentorBrad.jpeg";
import MentorLaco from "assets/img/faces/MentorLaco.jpeg";
import MentorGerald from "assets/img/faces/MentorGerald.jpeg";
import MentorDeb from "assets/img/faces/MentorDeb.jpeg";
import MentorLouise from "assets/img/faces/MentorLouise.jpeg";
import MentorTrevor from "assets/img/faces/MentorTrevor.jpeg";
import MentorGreame from "assets/img/faces/MentorGreame.jpeg";
import MentorFiza from "assets/img/faces/MentorFiza.jpeg";
import MentorIan from "assets/img/faces/MentorIan.jpeg";
import MentorShah from "assets/img/faces/MentorShah.jpeg";
import MentorStuart from "assets/img/faces/MentorStuart.jpeg";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(testimonialsStyle);

export default function MentorPage({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div className="cd-section" {...rest}>
      <div>
        <Header
          // brand="Material Kit PRO React"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="info"
          changeColorOnScroll={{
            height: 300,
            color: "info",
          }}
        />
      </div>
      {/* Testimonials 3 START */}
      <div className={classes.testimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                Meet Some of our Amazing Mentors
              </h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorAngie} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Angie Mardon</h4>
                  <Muted>
                    <h6>FOUNDER @ ENGINE ROOM HUB</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}As a passionate and driven supporter of WA’s business
                    community, I’m compelled to help entrepreneurs access
                    affordable advice support and exposure to help make their
                    businesses a success and keep our economy vibrant.{'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorBrad} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Brad George</h4>
                  <Muted>
                    <h6>BUSINESS ADVISOR @ BUSINESS FOUNDATIONS.</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}Proven and developed experience in sales and business
                    development, strategy, business and corporate advisory,
                    staff management, policy documentation, venture capital.
                    Passionate about assisting clients from all levels of
                    business achieve growth with desired outcomes.{'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorDeb} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Deb Banning</h4>
                  <Muted>
                    <h6>CEO & CO-FOUNDER @ BUSINESS AGILITY</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}I am a qualified and experienced management consultant
                    and business coach, specialising in agile transformation,
                    business improvement and growth. I have had 9 years
                    experience as a former CEO & MD, 10 years experience as a
                    Director on Boards and more than 20 years experience across
                    the mining, banking, government, education and NFP sectors.
                    I have lectured at James Cook University on the subject of
                    'Innovation & Entrepreneurship' at the MBA level
                    {'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorGerald} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Gerald Richards</h4>
                  <Muted>
                    <h6>CEO @ 3x5x7.com</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}I have over 30 years’ experience in the construction
                    and conduct of training programs in his role as consultant
                    and mentor to industry. I bring an unique blend of
                    accounting, administration, training, sales, marketing and
                    management skills that allows me to bring and relate to my
                    clients the analytical, theoretical and practical experience
                    necessary to ensure worthwhile and lasting results.{'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorGreame} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Graeme Sheard</h4>
                  <Muted>
                    <h6>START UP ADVISOR</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}During my professional career, I have worked in both
                    Asia and Australia where my diverse experiences meant that I
                    was an advisor to some of the largest OEM manufacturers and
                    financial institutions in the world. During that period of
                    my career, I focussed on creating practical solutions to
                    complex income tax and indirect tax situations. During the
                    last decade, I have taken some of those skills and applied
                    them to the start-up sector where I have advised on
                    commercial and strategic plans to guide start-ups in their
                    maturation to successful businesses. {'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorLouise} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Louise Gibson</h4>
                  <Muted>
                    <h6>BUSINESS COACH</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}I am a passionate and impactful business leader with
                    experience in Human Resources and transformational change. I
                    have led local, regional and global teams to deliver value
                    during the course of my time with global mining and
                    resources company, BHP. I am also a certified organisational
                    coach and get a lot out of seeing people realise their
                    potential. I truly believe in the importance of investing in
                    the mindsets and capabilities of students through every
                    stage of education, so we can grow strong and effective
                    leaders and cultures for the future of business and our
                    communities.
                    {'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorJohn} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>John Broons</h4>
                  <Muted>
                    <h6>ADVISOR</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}As a highly experienced mentor, coach and Specialist
                    Accredited Family Advisor for almost 30 years', I have been
                    immersed in business consulting since the early 1990s. I
                    work with business owners and executives on creating,
                    challenging and focusing their business strategy and widely
                    sharing my expertise through engagements as a professional
                    facilitator, presenter and Keynote Speaker. {'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorLaco} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Laco Vasika</h4>
                  <Muted>
                    <h6>CEO @ BE ClOUD SMART</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}Laco is the co-founder of Becloudsmart, a Gold Cloud
                    Productivity Microsoft Partner and has a career experience
                    spanning from startups to major multinationals. Laco is
                    passionate about solving business challenges and driving
                    business outcomes using Digital Transformation solutions.
                    {'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorFiza} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Fiza Ismail</h4>
                  <Muted>
                    <h6>FOUNDER @ MOVE-MOUNTAINS COACHING</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}I am Malaysian born and attended UWA followed by work
                    as an auditor with The State Government of Australia
                    (Department of Finance) for 6 years and a half, dealing with
                    top tiers of Australia’s top 100 Listed Companies. I am CPA
                    who mentors other young graduates and junior CPA associates
                    throughout their journey. I am also a keen mountaineer.
                    {'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            {/* <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img src={cardProfile6Square} alt="..." />
                  </a>
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Shah Turner</h4>
                  <Muted>
                    <h6>FOUNDER @ MMMENGLISH</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}I am a landscape architect turned internet entrepreneur
                    with experience in managing remote teams, workflow
                    automation, business strategy, design and software
                    development. I am also co-founder at The Ladies’ Project, a
                    social impact startup empowering women around the world who
                    are learning English.
                    {'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem> */}
            {/* <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img src={cardProfile6Square} alt="..." />
                  </a>
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Stuart Cattanach</h4>
                  <Muted>
                    <h6>FOUNDER @ RESULTS CONSULTING GROUP</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}When you have a genuine point of difference you never
                    have to compete on price again.​ Growth is more than
                    marketing. Your current business position reflects how well
                    you have positioned yourselves in direct comparison to your
                    competitors. I can teach you how to do that.
                    {'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem> */}
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorTrevor} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Trevor Rabey</h4>
                  <Muted>
                    <h6>DIRECTOR @ PERFECT PROJECT PLANNING</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}I am an engineer/consultant/trainer with a broad range
                    of relevant qualifications, experience, knowledge and
                    skills. I have helped hundreds of people and business
                    organisations to plan and organise their projects and use
                    their software tools better.
                    {'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorIan} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Dr. Ian Martinus</h4>
                  <Muted>
                    <h6>DIRECTOR @ WA AUSTCYBER INNOVATION HUB</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}I am economic development specialist with experience
                    delivering projects involving urban development and change
                    management impacts, international trade and investment,
                    eGovernance (information capture & aggregation), financial
                    management, export development, higher education and
                    technology innovation.
                    {'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorShah} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Shah Turner</h4>
                  <Muted>
                    <h6>FOUNDER @ MMMENGLISH</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}I am a landscape architect turned internet entrepreneur
                    with experience in managing remote teams, workflow
                    automation, business strategy, design and software
                    development. I am also co-founder at The Ladies’ Project, a
                    social impact startup empowering women around the world who
                    are learning English.
                    {'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorStuart} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Stuart Cattanach</h4>
                  <Muted>
                    <h6>FOUNDER @ WORTHI</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {'"'}At Worthi we plan and deliver your tactical strategy
                    around sales and marketing, whilst addressing the belief
                    systems and productivity of your people. I provide
                    businesses and people with the right strategy and equip them
                    with the tools to implement, producing remarkable results.
                    You can’t have one without the other.
                    {'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Testimonials 3 END */}
      <Footer
        content={
          <div>
            <div className={classes.left}>
              {/* <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/home" className={classes.block}>
                    Home
                  </Link>
                </ListItem>{" "}
                <ListItem className={classes.inlineBlock}>
                  <Link to="/teams" className={classes.block}>
                    Teams
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/blog-posts" className={classes.block}>
                    Blogs
                  </Link>
                </ListItem>
              </List> */}
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} Made by{" "}
              <a href="http://www.franked.io" target="_blank">
                franked.io
              </a>{" "}
              for a kinder web.
            </div>
          </div>
        }
      />
    </div>
  );
}
