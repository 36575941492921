/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';

// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
// sections for this page
import SectionDescription from 'views/PresentationPage/Sections/SectionDescription.js';
import SectionOverview from 'views/PresentationPage/Sections/SectionOverview.js';
import SectionTeams from 'views/PresentationPage/Sections/SectionTeams.js';
import SectionJudges from 'views/PresentationPage/Sections/SectionJudges.js';
import SectionFacilitators from 'views/PresentationPage/Sections/SectionFacilitators.js';
import SectionInnovationTours from 'views/PresentationPage/Sections/SectionInnovationTours';

import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js';
import './PresentationPage.scss';

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        // brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: 'info',
        }}
      />
      <Parallax
        image={require('assets/img/Homepage.jpeg')}
        className={classes.parallax}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand + ' brand'}>
                <h1>
                  JOONDALUP INNOVATION CHALLENGE
                  {/* <span className={classes.proBadge}>20.20</span> */}
                </h1>
                <h3 className={classes.title}>Future Proofing Joondalup.</h3>
                {/* <span className={classes.title + ' banner'}>2023 Information Coming Soon</span> */}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionDescription />
        <SectionTeams />
        <SectionInnovationTours />
        <SectionFacilitators />
        <SectionOverview />
        {/* <SectionJudges /> */}
        {/* <SectionContent /> */}
        {/* <SectionComponents /> */}
        {/* <SectionCards /> */}
        {/* <SectionContent /> */}
        {/* <SectionSections /> */}
        {/* <SectionExamples /> */}
        {/* <SectionFreeDemo /> */}
        {/* <SectionOverview /> */}
      </div>
      {/* <SectionPricing /> */}
      <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left}>
              <a
                href="https://www.franked.io"
                target="_blank"
                className={classes.footerBrand}
              >
                franked.io
              </a>
            </div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/about-us" className={classes.block}>
                    About Us
                  </Link>
                </ListItem>{' '}
                <ListItem className={classes.inlineBlock}>
                  <Link to="/participant-information" className={classes.block}>
                    Participant Information
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/blog-posts" className={classes.block}>
                    Blogs
                  </Link>
                </ListItem>
              </List>
            </div>
            <div className={classes.rightLinks}>
              <ul>
                {/* <li>
                  <Button
                    href="https://www.facebook.com/frankteam"
                    target="_blank"
                    color="facebook"
                    justIcon
                    simple
                  >
                    <i className="fab fa-facebook" />
                  </Button>
                </li> */}
                <li>
                  <Button
                    href="https://www.linkedin.com/company/the-frank-team"
                    target="_blank"
                    color="linkedin"
                    justIcon
                    simple
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        }
      />
    </div>
  );
}
