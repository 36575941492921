/*!

=========================================================
* Material Kit PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router';

import 'assets/scss/material-kit-pro-react.scss?v=1.8.0';

// pages for this product
import AboutUsPage from 'views/AboutUsPage/AboutUsPage.js';
import BlogPostPage from 'views/BlogPostPage/BlogPostPage.js';
import BlogPostsPage from 'views/BlogPostsPage/BlogPostsPage.js';
import ComponentsPage from 'views/ComponentsPage/ComponentsPage.js';
import ContactUsPage from 'views/ContactUsPage/ContactUsPage.js';
import LandingPage from 'views/LandingPage/LandingPage.js';
import PresentationPage from 'views/PresentationPage/PresentationPage.js';
import ErrorPage from 'views/ErrorPage/ErrorPage.js';
import TeamsPage from 'views/TeamsPage/TeamsPage.js';
import TeamsPageData from 'views/TeamsPage/TeamsData';
import TeamsPageData2021 from 'views/TeamsPage/TeamsData2021';
import TeamsPageData2022 from 'views/TeamsPage/TeamsData2022';
import TeamsPageData2023 from 'views/TeamsPage/TeamsData2023';
import MentorPage from 'views/MentorPage/MentorPage.js';
import MentorFAQPage from 'views/MentorFAQPage/MentorFAQPage.js';
import Mentors2021Page from 'views/Mentors2021Page/Mentors2021Page.js';
import ParticipantFAQPage from 'views/ParticipantFAQPage/ParticipantFAQPage.js';
import ParticipantSchedulePage from 'views/ParticipantSchedulePage/ParticipantSchedulePage.js';
import './index.scss';
import { getBlogPage } from 'utils/BlogUtils';
import { blogpostsdata } from 'views/BlogPostsData/BlogPostsData.js';
import Mentors2022Page from './views/Mentors2022Page/Mentors2022Page';
import Mentors2023Page from 'views/Mentors2023Page/Mentors2023Page';
import Mentors2024Page from 'views/Mentors2024Page/Mentors2024Page';

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/mentors" component={MentorPage} />
      <Route path="/mentor-information" component={MentorFAQPage} />
      <Route path="/mentor-profiles-2024" component={Mentors2024Page} />
      <Route path="/mentor-profiles-2023" component={Mentors2023Page} />
      <Route path="/mentor-profiles-2022" component={Mentors2022Page} />
      <Route path="/mentor-profiles-2021" component={Mentors2021Page} />
      <Route path="/participant-information" component={ParticipantFAQPage} />
      <Route path="/participant-schedule" component={ParticipantSchedulePage} />
      <Route
        path="/teams"
        component={() => <TeamsPage teams={TeamsPageData} />}
      />
      <Route
        path="/teams-2021"
        component={() => <TeamsPage teams={TeamsPageData2021} />}
      />
      <Route
        path="/teams-2022"
        component={() => <TeamsPage teams={TeamsPageData2022} />}
      />
      <Route
        path="/teams-2023"
        component={() => <TeamsPage teams={TeamsPageData2023} />}
      />
      <Route path="/blog-post" component={BlogPostPage} />
      <Route path="/blog-post1" component={getBlogPage(blogpostsdata['1'])} />
      <Route path="/blog-post2" component={getBlogPage(blogpostsdata['2'])} />
      <Route path="/blog-post3" component={getBlogPage(blogpostsdata['3'])} />
      <Route path="/blog-post4" component={getBlogPage(blogpostsdata['4'])} />
      <Route path="/blog-post5" component={getBlogPage(blogpostsdata['5'])} />
      <Route path="/blog-posts" component={BlogPostsPage} />
      <Route path="/components" component={ComponentsPage} />
      <Route path="/contact-us" component={ContactUsPage} />
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/error-page" component={ErrorPage} />
      <Route path="/" component={PresentationPage} />
    </Switch>
  </Router>,
  document.getElementById('root')
);
