import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import descriptionStyle from 'assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js';

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h5 className={classes.description}>
            The JOONDALUP INNOVATION CHALLENGE is co-created by{' '}
            <a href="http://joondalup.wa.gov.au" target="new window">
              City of Joondalup{' '}
            </a>{' '}
            and{' '}
            <a href="http://franked.io" target="new window">
              The FRANK Team{' '}
            </a>{' '}
            with key partners such as{' '}
            <a
              href="https://www.studyperth.com.au/?utm_term=study%20perth&utm_campaign=%5BSEARCH%5D+Generic+-+Domestic&utm_source=adwords&utm_medium=ppc&hsa_acc=9266043201&hsa_cam=13732986202&hsa_grp=122928894703&hsa_ad=530936723754&hsa_src=g&hsa_tgt=kwd-310480118315&hsa_kw=study%20perth&hsa_mt=b&hsa_net=adwords&hsa_ver=3&gclid=Cj0KCQjw756lBhDMARIsAEI0AgkQaNqfV2_NWOr-E4npgwEScRYOP3Fk7lzoNGr64H_PJiYEndKBGKQaAv4qEALw_wcB"
              target="new window"
            >
              Study Perth{' '}
            </a>{' '}
            and 5 outstanding educational institutions. Participating
            organisations include University of Western Australia, Edith Cowan
            University and North Metro TAFE, Australian Institute of Workplace
            Training and National Institute of Technology.50 international and
            local students participate in a fast-paced employability learning
            journey and establish their local networks. Over a period of 1.5
            weeks, inter-institute teams create a simulated start-up,
            identifying and addressing real-life problems facing residents and
            businesses in Joondalup. The student teams are supported and
            mentored by professional industry mentors. Students work and ideate
            virtually and in person, learning and applying the latest technology
            tools.
            {/* and 6 outstanding educational institutions. Participating
            organisations include University of Western Australia, Curtin
            University, Edith Cowan University and North Metro TAFE, Australian
            Institute of Workplace Training, National Institute of Technology
            and others.100 international and local students participate in a
            fast-paced employability learning journey and establish their local
            networks. Over a period of 1.5 weeks, inter-institute teams create a
            simulated start-up, identifying and addressing real-life problems
            facing residents and businesses in Joondalup. The student teams are
            supported and mentored by professional industry mentors. Students
            work and ideate virtually and in person, learning and applying the
            latest technology tools. */}
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
