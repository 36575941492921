import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import descriptionStyle from 'assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js';

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h5 className={classes.description}>
            The JOONDALUP INNOVATION CHALLENGE is co-created by City of
            Joondalup and Franked.io. We are hosting WA's foremost innovation
            challenge where student teams from many educational institutions
            will be working in multi-disciplinary teams to solve real challenges
            impacting Joondalup’s residents, businesses and communities. The
            students will learn and apply start-up methodology (i.e. Business
            Model Canvas) to come up with a new idea and plan it out in a
            realistic and feasible manner. They will have to learn new concepts
            rapidly and at pace, as well as working with a new team - which is
            challenging.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
