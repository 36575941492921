import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// react component for creating beautiful carousel
import Carousel from 'react-slick';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
import Share from '@material-ui/icons/Share';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
// core components
import Header from 'components/Header/Header.js';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CustomInput from 'components/CustomInput/CustomInput.js';

import headersStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js';

import bg12 from 'assets/img/bg12.jpg';
import office2 from 'assets/img/examples/office2.jpg';
import dg1 from 'assets/img/dg1.jpg';
import dg2 from 'assets/img/dg2.jpg';
import dg3 from 'assets/img/dg3.jpg';
import Tour1 from 'assets/img/Tour1.jpeg';
import Tour2 from 'assets/img/Tour2.jpeg';
import Tour3 from 'assets/img/Tour3.jpeg';

const useStyles = makeStyles(headersStyle);

export default function SectionInnovationTours({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    // we've set the className to cd-section so we can make smooth scroll to it
    <div className="cd-section" {...rest}>
      <div className={classes.sectionBlank} id="blanksection" />

      <div className={classes.container}>
        <div className="full-iframe">
          <iframe
            src="https://www.youtube.com/embed/Ip76KHubB18" 
          ></iframe>
        </div>
      </div>
      <div className={classes.sectionBlank} id="blanksection" />
      <div className={classes.container}>
        <div className="full-iframe">
         <iframe src="https://player.vimeo.com/video/993215371"></iframe>
        </div>
      </div>
      {/* HEADER 3 START */}
      {/* <div> */}
      {/* <GridContainer> */}
      {/* <div class="video-container"> */}
      {/* </div> */}
      {/* <GridItem xs={12} sm={6} md={6} 
                  className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
              <div>
              > */}
      {/* <h1 >
                      What is the Joondalup Innovation Challenge
                    </h1> */}
      {/* <h4 >
                      Join City of Joondalup for a walking tour of Edith Cowan
                      University (featuring the world’s largest permanent
                      periodic table), multimillion dollar Security Operations
                      Centre (SOC) and Edith Cowan House). Then drive-by the
                      Joondalup Learning Precinct, Joondalup CBD, Joondalup
                      Health Campus and Ocean Reef Marina development on a
                      Scavenger Hunt activity at Hillarys Boat Harbour
                      (facilitated by the Hillarys Traders Association). This
                      will be followed by Coffee Connections networking event
                      (hosted by the Joondalup Business Asssociation)
                    </h4> */}

      {/* <Button color="danger" size="lg">
                      Register Here
                    </Button> */}
      {/* </GridItem> */}
      {/* </GridContainer> */}

      {/* <Carousel {...settings}> */}
      {/* Carousel 1 START */}
      {/* <div>
            <div
              className={classes.pageHeader}
              // style={{ backgroundImage: `url("${Tour1}")` }}
            > */}
      {/* <div className={classes.container}>
                <GridContainer>
                  <div class="video-container">
                    <iframe src="https://www.youtube.com/embed/Ip76KHubB18"></iframe>
                  </div>
                  <GridItem xs={12} sm={6} md={6}>
                    <h1 className={`video-title ${classes.title}`}>
                      What is the Joondalup Innovation Challenge
                    </h1>
                    <h4 className="video-title">
                      Join City of Joondalup for a walking tour of Edith Cowan
                      University (featuring the world’s largest permanent
                      periodic table), multimillion dollar Security Operations
                      Centre (SOC) and Edith Cowan House). Then drive-by the
                      Joondalup Learning Precinct, Joondalup CBD, Joondalup
                      Health Campus and Ocean Reef Marina development on a
                      Scavenger Hunt activity at Hillarys Boat Harbour
                      (facilitated by the Hillarys Traders Association). This
                      will be followed by Coffee Connections networking event
                      (hosted by the Joondalup Business Asssociation)
                    </h4>
                    <br /> */}
      {/* <Button color="danger" size="lg">
                      Register Here
                    </Button> */}
      {/* </GridItem>
                </GridContainer>
              </div>
            </div> */}
      {/* </div> */}
      {/* Carousel 1 END */}
      {/* Carousel 2 START */}
      {/* <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${Tour2}")` }}
            > */}
      {/* <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={8}
                    md={8}
                    // className={classNames(
                    //   classes.mlAuto,
                    //   classes.mrAuto,
                    //   classes.textCenter
                    // )}
                  > */}
      {/* <h1 className={classes.title}>
                      Innovation Tour #2: StudyPerth Student Hub & University of
                      Western Australia
                    </h1>
                    <h4>
                      This Innovation Tour will start at StudyPerth Student Hub
                      and will feature Tom Young, CEO of udrew - a construction
                      startup. This will be followed by a visit UWA's
                      state-of-art EZONE building and facilitated by UWA’s very
                      own cybersecurity academic guru, Dr Jin Hong, alongside
                      HBF Senior Manager, Andrew Bullen and Kyfer Bushe-Jones.
                      The session will be interactive and designed to enhance
                      students’ understanding of the importance of cybersecurity
                      to Perth and the world. Dr Jin Hong is a Senior Lecturer
                      at UWA in the area of cybersecurity. Andrew is a senior
                      leader in Technology Risk at HBF Health, managing multiple
                      Cyber Security and Risk functions in the Technology
                      division. Kyfer Bushe-Jones is Defensive Cyber Operations
                      Lead, at HBF Health.
                    </h4>
                    <br /> */}
      {/* <Button color="danger" size="lg">                      
                      <a href="https://forms.gle/3jMfrvfCVPpgLZxx9" target="_blank">Register Here</a>
                    </Button> */}
      {/* </GridItem>
                </GridContainer>
              </div>
            </div>
          </div> */}
      {/* Carousel 2 END */}
      {/* Carousel 3 START */}
      {/* <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${Tour3}")` }}
            >
              <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={7}
                    md={7}
                    className={classNames(classes.mlAuto, classes.textRight)}
                  >
                    <h1 className={classes.title}>
                      Innovation Tour #3: Curtin University
                    </h1>
                    <h4>
                      This tour will be feature the latest innovation spaces and
                      developments at Curtin University including the
                      University's partnership with NASA.
                    </h4>
                    <br />
                    <div>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div> */}
      {/* Carousel 3 END */}
      {/* </Carousel> */}
      {/* </div> */}
      {/* HEADER 3 END */}
    </div>
  );
}
