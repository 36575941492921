import { cardTitle } from 'assets/jss/material-kit-pro-react';
import { title } from 'assets/jss/material-kit-pro-react';
import {
  container,
  section,
  grayColor,
} from 'assets/jss/material-kit-pro-react.js';

const descriptionStyle = {
  container,
  description: {
    color: grayColor[0],
    textAlign: 'center',
  },
  section: {
    ...section,
    padding: '70px 0px',
    '& h4$description': {
      fontSize: '1.5em',
    },
  },
  title: {
    ...title,

    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
    textAlign: 'center',
  },
  cardTitle: {
    ...cardTitle,
    marginTop: 0,
  },
  listingClass: {
    textAlign: 'start !important',
  },
  features: {
    textAlign: 'center !important',
    paddingTop: '30px',
    paddingBottom: '0px',
    '& p': {
      fontSize: '16px',
      lineHeight: '1.6em',
    },
  },
  cardBorder: {
    // border: "4px solid",
    // borderColor: grayColor[4],
    boxShadow: `0 4px 8px 4px ${grayColor[2]}, 0 6px 20px 2px ${grayColor[2]}`,
    '@media (min-width: 600px)': {
      height: '1300px',
    },
    '@media (min-width: 768px)': {
      height: '800px',
    },
    '@media (min-width: 960px)': {
      height: '1100px',
    },
    '@media (min-width: 992px)': {
      height: '700px',
    },
    '@media (min-width: 1024px)': {
      height: '600px',
    },

    '@media (min-width: 1200px)': {
      height: '500px',
    },
  },
  cardImage: {
    boxShadow: 'none !important',
    marginTop: '15px !important',
    width: '200px !important',
    height: '100px !important',
    objectFit: 'contain !important',
    '@media (min-width: 600px)': {
      width: '100px !important',
      height: '100px !important',
    },
    '@media (min-width: 960px)': {
      width: '100px !important',
      height: '100px !important',
    },
    '@media (min-width: 990px)': {
      width: '150px !important',
      height: '100px !important',
    },
  },
};

export default descriptionStyle;
