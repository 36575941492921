const TeamsData2023 = [
  {
    motto: '1st Place',
    id: 8,
    name: 'EcoH2O',
    url: 'https://youtu.be/V36AAnQNj_w?si=eOi3dXLgxNEz3vA_',
    image: require('assets/img/teamLogos2023/team8.png'),
  },
  {
    motto: '2nd Place',
    id: 10,
    name: 'Aqua Saver',
    url: 'https://youtu.be/k-phfN8fQyc',
    image: require('assets/img/teamLogos2023/team10.png'),
  },
  {
    motto: '3rd Place',
    id: 3,
    name: 'Magnum Opus',
    url: 'https://youtu.be/ZYCDYvrvlYo',
    image: require('assets/img/teamLogos2023/team3.png'),
  },
  {
    motto: 'finalist',
    id: 2,
    name: 'Drip Drop Squad',
    url: 'https://www.youtube.com/watch?v=vQ4uc3ToaEM',
    image: require('assets/img/teamLogos2023/team2.png'),
  },
  {
    motto: 'finalist',
    id: 5,
    name: 'JIT - Joondalup Innovation Tycoons',
    url: 'https://www.youtube.com/watch?v=mAJwQsdHV08',
    image: require('assets/img/teamLogos2023/team5.png'),
  },
  {
    motto: '',
    id: 1,
    name: 'CryptoKnights',
    url: 'https://youtu.be/5Zf75hKihxY?si=o7ifOFfDcihMvp4F',
    image: require('assets/img/teamLogos2023/team1.png'),
  },
  {
    motto: '',
    id: 6,
    name: 'Joondalup CATs',
    url: 'https://youtu.be/oiAWLdK0mUQ',
    image: require('assets/img/teamLogos2023/team6.png'),
  },
  {
    motto: '',
    id: 9,
    name: 'WaterGrn',
    url: 'https://youtu.be/EtICOhSWqwE?feature=shared',
    image: require('assets/img/teamLogos2023/team9.png'),
  },
];

export default TeamsData2023;
