import React from "react";
import PropTypes from "prop-types";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Link } from "react-router-dom";
import Info from "components/Typography/Info.js";
import { makeStyles } from "@material-ui/core";
import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";
import "./BlogCard.scss";

const useStyles = makeStyles(sectionInterestedStyle);

export function BlogCard(props) {
  const classes = useStyles();
  const { blogImage, backgroundImage, heading, description, link } = props;
  return (
    <Card className="blog-card" plain blog>
      <CardHeader image plain>
        <Link to={link}>
          <img className="feature-image" src={blogImage} alt="..." />
        </Link>
        <div
          className={classes.coloredShadow}
          style={{
            backgroundImage: "url(" + backgroundImage + ")",
            opacity: "1"
          }}
        />
      </CardHeader>
      <CardBody plain>
        <Info>{/* <h6>ENTERPRISE</h6> */}</Info>
        <div className="text-center">
          <Link to={link}>
            <h4 className={classes.cardTitle}>{heading}</h4>
          </Link>
        </div>
        <div className="text-center">
          <Link to={link}>
            <p className={classes.description}>{description}</p>
          </Link>
        </div>
        <div className="mt-1 text-center">
          <Link className="btn-link" to={link}>
            Read More
          </Link>
        </div>
      </CardBody>
    </Card>
  );
}

BlogCard.propTypes = {
  heading: PropTypes.string.isRequired,
  blogImage: PropTypes.any.isRequired,
  backgroundImage: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  link: PropTypes.any.isRequired
};
