const TeamsData2022 = [
  {
    motto: '1st Place',
    id: 7,
    name: 'Sixes and Sevens',
    url: 'https://www.youtube.com/watch?v=CZWhk3D81RU&ab_channel=KayleighHawke',
    image: require('assets/img/teamLogos2022/team 7 Sixes and Sevens.png'),
  },
  {
    motto: '2nd Place',
    id: 10,
    name: 'Endeavour',
    url: 'https://youtu.be/yDknIy2St30',
    image: require('assets/img/teamLogos2022/Team10Logo - Dylan de Souza.jpg'),
  },
  {
    motto: '3rd Place',
    id: 3,
    name: 'Plastic Hearts Studio',
    url: 'https://youtu.be/V0KV6MYVsRY ',
    image: require('assets/img/teamLogos2022/Team 3 Plastic hears studios logo.JPG'),
  },
  {
    motto: 'finalist',
    id: 8,
    name: 'JTech',
    url: 'https://youtu.be/tiaUjTSlRL4',
    image: require('assets/img/teamLogos2022/JTech - Charlotte Yeap.PNG'),
  },
  {
    motto: 'finalist',
    id: 11,
    name: 'Eco.lution',
    url: 'https://youtu.be/sMJUAOQ_FcI',
    image: require('assets/img/teamLogos2022/IMG-20220912-WA0003 - Matthew Moore.jpg'),
  },
  {
    motto: '',
    id: 1,
    name: 'ASSIMILATE',
    url: 'https://youtu.be/5jhIdkdQZmM',
    image: require('assets/img/teamLogos2022/Team 1 ASSIMILATE Logo.jpeg'),
  },
  {
    motto: '',
    id: 14,
    name: 'Revive Joondalup ',
    url: 'https://www.youtube.com/watch?v=-hBexIhMqs4',
    image: require('assets/img/teamLogos2022/Revive Joondalup - Ryan Ngaw.jpg'),
  },
  {
    motto: '',
    id: 5,
    name: 'DEcipherers',
    url: 'https://youtu.be/4giK6qyhXPU',
    image: require('assets/img/teamLogos2022/IMG-20220911-WA0005 - Sneha Mukhopadhyay.jpg'),
  },
];

export default TeamsData2022;
