import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

import Speakermelissa from "assets/img/faces/Speakermelissa.jpeg";
import Speakerglenn from "assets/img/faces/Speakerglenn.jpeg";
import Speakerlucas from "assets/img/faces/Speakerlucas.jpeg";
import Speakerchibs from "assets/img/faces/Speakerchibs.jpeg";
import judgeangela from "assets/img/faces/judgeangela.jpeg";
import judgeneil from "assets/img/faces/judgeneil.jpeg";
import judgenashid from "assets/img/faces/judgenashid.jpeg";

const useStyles = makeStyles(teamsStyle);

export default function SectionJudges({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Our Judges</h2>
              <h5 className={classes.description}>
                Meet the experts from industry and government who will be
                joining in as judges for the JOONDALUP INNOVATION CHALLENGE
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img src={judgeangela} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Angela Beechey</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>
                      Senior Manager, Study Perth
                    </h6>
                  </Muted>
                  <p className={classes.description}>
                    Angela champions WA as a first-choice global education
                    destination; built from a diverse, prospering community.
                    Angela has a MBA from Deakin University and is a specialist
                    in Marketing.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button
                    href="https://www.linkedin.com/in/angelabeechey/"
                    target="blank"
                    justIcon
                    simple
                    color="linkedin"
                    className={classes.btn}
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                  {/* <Button
                    href="#pablo"
                    justIcon
                    simple
                    color="facebook"
                    className={classes.btn}
                    onClick={(e) => e.preventDefault()}
                  > */}
                  {/* <i className="fab fa-facebook" />
                  </Button>
                  <Button
                    href="#pablo"
                    justIcon
                    simple
                    color="google"
                    className={classes.btn}
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-google" />
                  </Button> */}
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="" onClick={(e) => e.preventDefault()}>
                    <img src={judgeneil} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Neil Gerrard</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>
                      CEO, Errant Venture
                    </h6>
                  </Muted>
                  <p className={classes.description}>
                    Neil is a Chartered Accountant and the CEO of Errant
                    Venture. He is passionate about problem solving and leads
                    the Joondalup Business Association.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button
                    href="https://www.linkedin.com/in/gerry01/"
                    target="blank"
                    justIcon
                    simple
                    color="linkedin"
                    className={classes.btn}
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                  {/* <Button
                    href="#pablo"
                    justIcon
                    simple
                    color="dribbble"
                    className={classes.btn}
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-dribbble" />
                  </Button>
                  <Button
                    href="#pablo"
                    justIcon
                    simple
                    color="linkedin"
                    className={classes.btn}
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-linkedin" />
                  </Button> */}
                </CardFooter>
              </Card>
            </GridItem>
            {/* <GridItem xs={12} sm={6} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img src={Speakerlucas} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Lucas Hakewell</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>
                      Program Manager, Entrepreneurship Education
                    </h6>
                  </Muted>
                  <p className={classes.description}>
                    Lucas loves to solve interesting problems. Formerly working
                    at BT Financial Group and Westpac, Lucas brings an innotive
                    and fresh approach to innovation. Lucas will run a
                    Prototyping Masterclass for the JOONDALUP INNOVATION
                    CHALLENGE.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button
                    href="https://www.linkedin.com/in/lucashakewill/"
                    target="blank"
                    justIcon
                    simple
                    color="linkedin"
                    className={classes.btn}
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                  {/* <Button
                    href="#pablo"
                    justIcon
                    simple
                    color="dribbble"
                    className={classes.btn}
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-dribbble" /> */}
            {/* </Button> */}
            {/* </CardFooter>
              </Card>
            </GridItem> */}{" "}
            <GridItem xs={12} sm={6} md={4}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img src={judgenashid} alt="..." />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Nashid Chowdhury</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>
                      Economic Development Lead, City of Joondalup
                    </h6>
                  </Muted>
                  <p className={classes.description}>
                    Nashid is an accomplished international marketing,
                    communications and business development advisor, with a
                    solid track record of accomplishment in assisting Australian
                    businesses to successfully promote their products and
                    services in international markets.
                  </p>
                </CardBody>
                <CardFooter profile plain className={classes.justifyContent}>
                  <Button
                    justIcon
                    simple
                    color="linkedin"
                    href="https://www.linkedin.com/in/nashid-chowdhury-36a7613a/"
                    target="blank"
                    className={classes.btn}
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                  {/* <Button
                    justIcon
                    simple
                    color="twitter"
                    href="#pablo"
                    className={classes.btn}
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-twitter" />
                  </Button> */}
                  {/* <Button
                    justIcon
                    simple
                    color="dribbble"
                    href="#pablo"
                    className={classes.btn}
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-dribbble" />
                  </Button> */}
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
