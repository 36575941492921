import React from 'react';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

// @material-ui icons
import Apps from '@material-ui/icons/Apps';
import ViewDay from '@material-ui/icons/ViewDay';
import ViewCarousel from '@material-ui/icons/ViewCarousel';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import descriptionStyle from 'assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import prize1 from 'assets/img/prize/prize1.png';
import prize2 from 'assets/img/prize/prize2.png';
import prize3 from 'assets/img/prize/prize3.jpg';
const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={8} sm={8}>
            <h4 className={classes.description}>
              The JOONDALUP INNOVATION CHALLENGE is an Employability &
              Entrepreneurship Challenge designed to build the employability and
              resilience of students. It brings together educators, business
              leaders, entrepreneurs and artists to build a community unlike any
              other
            </h4>
          </GridItem>
        </GridContainer>
        <div className={classes.features}>
          <GridContainer>
            <GridItem md={4} sm={4}>
              <InfoArea
                title="Over 8 Multidisciplinary Student Teams"
                description="50 international and local students working together to solve real-world problems impacting Joondalup residents and businesses."
                icon={Apps}
                iconColor="danger"
                vertical={true}
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <InfoArea
                title="5 Outstanding Australian and International Educational Institutions"
                description="Educational collaboration at it's best to serve both students and the Joondalup community"
                icon={ViewDay}
                iconColor="primary"
                vertical={true}
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <InfoArea
                title="1.5 Weeks of Fast Paced Entrepreneurial Challenge"
                description="Students learn and work virtually and in person, applying the latest technology and business tools."
                icon={ViewCarousel}
                iconColor="success"
                vertical={true}
              />
            </GridItem>
          </GridContainer>
          {/* <h2 className={classes.title}>Finalist Prizes</h2> */}

          {/* <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card profile plain className={classes.cardBorder}>
                <CardHeader image plain>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={prize1} alt="..." className={classes.cardImage} />
                  </a>
                </CardHeader>
                <CardBody
                  style={{
                    padding: '0.9375rem',
                  }}
                >
                  <h4 className={classes.cardTitle}>First Prize</h4>
                  <ul className={classes.listingClass}>
                    <li>
                      Professional UX/UI hi-fidelity designs of the project/app
                      by Redi Software’s inhouse software design experts.
                    </li>
                    <li>
                      Professional consultation & mentoring sessions to help
                      steer, refine, architect and plan the product.
                    </li>
                    <li>
                      Shared desk space in the Redi Software office for 4 weeks,
                      surrounded by our extensive professional software team to
                      help, advice and guide with the project.
                    </li>
                    <li>
                      The opportunity to pitch to Redi software’s network of
                      Angel investors.
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card profile plain className={classes.cardBorder}>
                <CardHeader image plain>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={prize2} alt="..." className={classes.cardImage} />
                  </a>
                </CardHeader>

                <CardBody style={{ padding: '0.9375rem' }}>
                  <h4 className={classes.cardTitle}>Second Prize</h4>
                  <h4 className={classes.cardTitle}>
                    Exclusive Placement in Joondalup Plus Eight Sprint Program
                    (Feb 2024 intake).
                  </h4>
                  <Muted>
                                                <h6
                                                    className={
                                                        classes.cardCategory
                                                    }
                                                >
                                                    Founder & CEO at Banksia
                                                    Academy
                                                </h6>
                                            </Muted>
                  <p>
                    A highly sought-after spot in the esteemed Joondalup Plus
                    Eight Sprint Program. This follow-on program is specifically
                    designed to accelerate the entrepreneurial journey by
                    providing invaluable mentorship, resources, and guidance.
                    Valued at $3,000
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card profile plain className={classes.cardBorder}>
                <CardHeader image plain>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={prize3} alt="..." className={classes.cardImage} />
                  </a>
                </CardHeader>

                <CardBody style={{ padding: '0.9375rem' }}>
                  <h4 className={classes.cardTitle}>Third Prize</h4>
                  <h4 className={classes.cardTitle}>
                    Careers Masterclass with Careers Success Australia
                  </h4>
                  <Muted>
                                                <h6
                                                    className={
                                                        classes.cardCategory
                                                    }
                                                >
                                                    Founder & CEO at Banksia
                                                    Academy
                                                </h6>
                                            </Muted>
                  <p>
                    A 60-minute team Careers Masterclass with one of Australia’s
                    top Career Coaches, Langdon Rodda. The Masterclass will give
                    you the edge you need to secure graduate employment! Enhance
                    your CV, Cover Letter and Interview skills in this Careers
                    Masterclass session!
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer> */}
        </div>
      </div>
    </div>
  );
}
