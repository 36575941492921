import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import PropTypes from 'prop-types';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import b01 from 'assets/img/b01.jpg';

import teamsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';
import { TeamCard } from 'components/TeamCard/TeamCard';

const useStyles = makeStyles(teamsStyle);

export default function TeamsPage({ title, teams }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        // brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: 'info',
        }}
      />
      <div className="cd-section">
        <div
          className={classes.team + ' ' + classes.section}
          style={{ backgroundImage: `url(${b01})` }}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={
                  classes.mlAuto +
                  ' ' +
                  classes.mrAuto +
                  ' ' +
                  classes.textCenter
                }
              >
                <h2 className={classes.title}>
                  JOONDALUP INNOVATION CHALLENGE
                </h2>
                <h2 className={classes.title}>{title}</h2>
                <h5 className={classes.descriptionWhite}>
                  Meet our amazing JOONDALUP INNOVATION CHALLENGE teams. These
                  teams came together to work on real-life problems impacting
                  residents and businesses in Joondalup.
                </h5>
              </GridItem>
            </GridContainer>
            <GridContainer>
              {teams.map((team, index) => (
                <GridItem xs={12} sm={4} md={4} key={index}>
                  <TeamCard
                    id={team.id}
                    name={team.name}
                    image={team.image}
                    motto={team.motto}
                    url={team.url}
                  />
                </GridItem>
              ))}
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

TeamsPage.propTypes = {
  title: PropTypes.string.isRequired,
  teams: PropTypes.array.isRequired,
};
