import React from "react";
import { Link } from "react-router-dom";

// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FormatQuote from "@material-ui/icons/FormatQuote";
import Star from "@material-ui/icons/Star";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Muted from "components/Typography/Muted.js";
import Warning from "components/Typography/Warning.js";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";

import dg2 from "assets/img/dg2.jpg";
import cardProfile1Square from "assets/img/faces/card-profile1-square.jpg";
import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";
import cardProfile4Square from "assets/img/faces/card-profile4-square.jpg";
import cardProfile6Square from "assets/img/faces/card-profile6-square.jpg";
import MentorAngie from "assets/img/faces/MentorAngie.jpeg";
import MentorJohn from "assets/img/faces/MentorJohn.jpeg";
import MentorBrad from "assets/img/faces/MentorBrad.jpeg";
import MentorLaco from "assets/img/faces/MentorLaco.jpeg";
import MentorGerald from "assets/img/faces/MentorGerald.jpeg";
import MentorDeb from "assets/img/faces/MentorDeb.jpeg";
import MentorLouise from "assets/img/faces/MentorLouise.jpeg";
import MentorTrevor from "assets/img/faces/MentorTrevor.jpeg";
import MentorGreame from "assets/img/faces/MentorGreame.jpeg";
import MentorFiza from "assets/img/faces/MentorFiza.jpeg";
import MentorIan from "assets/img/faces/MentorIan.jpeg";
import MentorShah from "assets/img/faces/MentorShah.jpeg";
import MentorStuart from "assets/img/faces/MentorStuart.jpeg";
import MentorDawn from "assets/img/faces/MentorDawn.jpg";
import MentorMegan from "assets/img/faces/MentorMegan.png";
import MentorGreg from "assets/img/faces/MentorGreg.jpg";
import MentorAndrew from "assets/img/faces/MentorAndrew.jpg";
import MentorKate from "assets/img/faces/MentorKate.jpg";
import MentorAish from "assets/img/faces/MentorAish.jpg";
import MentorKim from "assets/img/faces/MentorKim.jpeg";
import MentorSuzanne from "assets/img/faces/MentorSuzanne.jpg";
import MentorJiaranai from "assets/img/faces/MentorJiaranai.jpg";
import MentorDonna from "assets/img/faces/MentorDonna.jpg";
import MentorCatriona from "assets/img/faces/MentorCatriona.jpeg";
import MentorYvonne from "assets/img/faces/MentorYvonne.jpg";
import MentorDanielle from "assets/img/faces/MentorDanielle.jpeg";
import MentorAmanda from "assets/img/faces/MentorAmanda.jpeg";
import MentorPaul from "assets/img/faces/MentorPaul.jpeg";
import MentorHeather from "assets/img/faces/MentorHeather.jpeg";
import MentorJacqui from "assets/img/faces/MentorJacqui.jpeg";
import MentorJason from "assets/img/faces/MentorJason.jpeg";
import MentorEunice from "assets/img/faces/MentorEunice.jpeg";
import MentorAndrea from "assets/img/faces/MentorAndrea.jpeg";


import Woman from "assets/img/faces/Woman.png";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(testimonialsStyle);

export default function Mentors2021Page({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div className="cd-section" {...rest}>
      <div>
        <Header
          // brand="Material Kit PRO React"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="info"
          changeColorOnScroll={{
            height: 300,
            color: "info",
          }}
        />
      </div>
      {/* Testimonials 3 START */}
      <div className={classes.testimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                Meet Some of our Amazing Mentors
              </h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorDawn} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Dawn McAleenan</h4>
                  <Muted>
                    <h6>CEO @ ESSENTIAL PERSONNEL</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}With over eighteen years of experience within the not for profit sector, Dawn has managed a range of services at a national, regional and local community levels across the UK and Western Australia. 
                    Dawn’s interest has always been in reducing the gap of social exclusion for vulnerable clients through targeted provision and community integration.{''}
                  </h5>
                  <h5 className={classes.cardDescription}>
                    {''}Dawn was also awarded as a 2021 Business News 40 Under 40 Winner. The 40 Under 40 Awards recognise and celebrate the states leading entrepreneurs, innovators and future business leaders under the age of 40.{''}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorLouise} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Louise Gibson</h4>
                  <Muted>
                    <h6>FOUNDER & DIRECTOR, STRATEGIC PEOPLE PARTNER & COACH @ WITH VERVE</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Louise is a passionate and impactful business leader with
                    experience in Human Resources and transformational change. She
                    has led local, regional and global teams to deliver value
                    during the course of her time with global mining and
                    resources company, BHP. {''}
                  </h5>
                  <h5 className={classes.cardDescription}>
                    {''}She is a certified organisational
                    coach and gets a lot out of seeing people realise their
                    potential. She truly believes in the importance of investing in
                    the mindsets and capabilities of students through every
                    stage of education, so we can grow strong and effective
                    leaders and cultures for the future of business and our
                    communities.{''}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorFiza} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Fiza Ismail</h4>
                  <Muted>
                    <h6>FOUNDER @ MOVE-MOUNTAINS COACHING</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}She is Malaysian born and attended UWA followed by work
                    as an auditor with The State Government of Australia
                    (Department of Finance) for 6 years and a half, dealing with
                    top tiers of Australia’s top 100 Listed Companies. She is a CPA
                    who mentors other young graduates and junior CPA associates
                    throughout their journey. She is also a keen mountaineer.
                    {''}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorGreg} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Greg Rogers</h4>
                  <Muted>
                    <h6>CEO & FOUNDER @ REthink HQ</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                  Creator of the Rethink Infinity Sales Pipeline and the 5Ps framework, Greg is a Business Performance Coach who helps businesses to increase sales, improve their customer experience and lift productivity.
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorMegan} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Megan Del Borrello</h4>
                  <Muted>
                    <h6>FOUNDER @ BEHIND THE BRANDS</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Megan Del Borrello’s ethos is to empower and advance women through entrepreneurship, having experienced gender bias in her professional career. She is the Founder of Behind the Brands, a platform that supports female entrepreneurs in Australia in building successful and sustainable businesses {''}
                  </h5>
                  <h5 className={classes.cardDescription}>
                  {''}Named as one of Inside Small Businesses Top 50 Small Business Leaders in 2019, Megan has over 20 years marketing experience and is a member of the Australian Institute of Marketing and Alumni of WA Leaders.{''}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorJiaranai} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Jiaranai Keatnuxsuo</h4>
                  <Muted>
                    <h6>TECHNICAL ACCOUNT MANAGER @ MICROSOFT</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Jia is a thriving Tech Optimiser, a STEM Advocate, a TEDx Speaker, a multi-awards winner of Women in Technology Western Australia 2019, NASA Space App Challenge, Energy Hack, Transport Hack WA and UWA Innovation Challenge. Jia works at Microsoft.{''}
                    </h5>
                    <h5 className={classes.cardDescription}>
                    {''}She is a Winner of Women in Technology Western Australia 2019. You often find her involved in community events that increase technology accessibility and inclusiveness to the underrepresented groups such as encouraging other women to come into tech and inspiring disadvantaged students to be curious and confident in the world of STEM.{''}</h5>
                </CardBody>
            </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorAndrew} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Andrew Rose</h4>
                  <Muted>
                    <h6>STARTUP & GROWTH ADVISOR @ SOLUTION-22</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Andrew has worked in numerous startups, new products and new businesses over the last 20+ years, at all levels from defining products and markets to raising funds, making acquisitions and negotiating exits.He currently works as a consultant helping existing and aspiring businesses find the path to success through innovation.{''}
                  </h5>
              
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorKate} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Kate Kirwin</h4>
                  <Muted>
                    <h6>FOUNDER SHE CODES</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Kate is a passionate advocate for women in STEM and the innovation ecosystem. Founder of She Codes, she has led the steep growth of the project since 2015. Thanks to her work, more than 4500 women have learnt to code through free programs.. With a regional upbringing, she strongly believes in empowering women from the world’s remotest regions, from as far north as Port Hedland and Karratha, and east as far as Brisbane.{''}
                  </h5>
                  <h5 className={classes.cardDescription}>
                    {''}Kate was recently awarded a Business News 40 under 40 award, the West Australian Rising Star award and was a runner up for the 2019 and 2020 Seven News Young Achiever Awards.{''}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorAish} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Aish Srinivas</h4>
                  <Muted>
                    <h6>CONSULTANT @ JOURNEYONE</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Aish is all about empowering diverse and inclusive teams to be at their best. Her emerging career has already exposed her to culturally diverse environments as she works across 4 continents (Europe, Asia, Africa & Oceania) providing consultancy in Digital Strategy, Change Management, Human Centered Design, Business Analysis and more. Aish is immersed in the entrepreneurial scene in Perth having co-founded and launched multiple start-ups, and participating in and hosting hackathons that aim to address global challenges.{''}
                  </h5>
                  <h5 className={classes.cardDescription}>
                    {''}Aish is the 2020 WiTWA Tech [+] 20 Award Winner and  2021 Business News 40 under 40 nominee.{''}

                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorKim} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Kim Smith</h4>
                  <Muted>
                    <h6>FOUNDING DIRECTOR & CEO @ STANDING STRONG INTERNATIONAL</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Kim Smith is the founder of Standing Strong Clubs, an international health and wellbeing organisation providing preventative physical and mental health and wellbeing programs for children and teens. Since 2013, Kim has grown Standing Strong Clubs into an award-winning licensing program which has scaled to 21 locations across WA, NSW, QLD, VIC and the USA.{''}
                  </h5>
                  <h5 className={classes.cardDescription}>
                    {''}In 2020, Kim was awarded the Telstra Business Womens’ Award for Purpose & Social Enterprise WA and was the 2021 winner of the Business News 40Under40 Award for Community & Social Enterprise. Kim and Standing Strong Clubs have been endorsed by Oprah Winfrey and have been featured on The Today Show, Daily Telegraph, ABC Radio, The Western Times, Girlfriend Magazine, Dolly Magazine and many more media outlets.{''}</h5>
                </CardBody>
              </Card>
            </GridItem>
               <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorSuzanne} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Suzanne Dodds</h4>
                  <Muted>
                    <h6>MANAGING DIRECTOR @ HOME FILTRATION</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Suzanne Dodds is the Founder of Complete Home Filtration and the Inventor of AttapureTM a world first and patented PFAS removing water filtration system. Launching her first business when she was 24, Suzanne is an experienced founder and award winning water industry professional having built businesses in Australia, Spain and South Korea. Suzanne has a Bachelor of Science from the QUeens University in Belfast and an MBA from UWA.{''}</h5>
                  <h5 className={classes.cardDescription}>
                  {''}2019 saw Suzanne win the WiTWA Tech [+] 20- First Among Equals award in the Women in Technology awards for her work in water filtration and PFAS remediation.  Most recently CHF won the WA Business News 2020 Rise Start Up of the Year and was shortlisted in the top 3 businesses of the year, having won the WBA Start up of the year and Innovator of the Year in 2019.{''}</h5>
                    </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorDonna} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Donna Edwards</h4>
                  <Muted>
                    <h6>CEO @ SHOUTOUT</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Donna has 20 years’ experience working in the IT industry. She is the CEO of SHOUTOUT, a simple recognition program helping organisations retain their talent and a BDM for Amazon Web Services in the Training and Certification team. With an MBA and MHRM, Donna is an international conference speaker and regular panelist on diversity and leadership topics.{''}</h5>
                  <h5 className={classes.cardDescription}>
                    {''}She is an author and a WA Business News, 40 under 40 award winner for the Professional Services category (2019). Donna volunteers for a multitude of NFP community groups in the tech space and is passionate about helping others succeed and inspiring confidence. She also loves a good challenge.{''}</h5>
                    </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorDeb} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Deb Banning</h4>
                  <Muted>
                    <h6>CEO & CO-FOUNDER @ BUSINESS AGILITY</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Deb is a qualified and experienced management consultant and business coach, specialising in agile transformation, business improvement and growth. She has had 9 years experience as a former CEO & MD, 10 years experience as a Director on Boards and more than 20 years experience across the mining, banking, government, education and NFP sectors. She has lectured at James Cook University on the subject of 'Innovation & Entrepreneurship' at the MBA level.{''}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
               <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorCatriona} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Catriona Forde</h4>
                  <Muted>
                    <h6>FOUNDER & CONSULTANT @ caIT</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Caitriona is passionate about Cyber Security and its impact on small to medium sized businesses. As an Independent consultant, Caitriona educates organisations on Cyber Security. She conducts IT Health checks and Cyber Security Audits as well as training.Caitriona is on a mission to educate smes and the community at large on cyber security. With this in mind, she has developed several initiatives and resources. These include community sessions on staying safe online and mentorship of student teachers as well as podcasts and webinars.{''}</h5>
                    <h5 className={classes.cardDescription}>
                    {''}She is a winner of WiTWA Tech [+] 20 Award Winner{''}</h5>
                    </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorYvonne} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Yvonne Power</h4>
                  <Muted>
                    <h6>COFOUNDER & COO @ VILLAGE ENERGY</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Yvonne is passionate about building a sustainable energy future, especially in communities and developing nations. Yvonne is co-founder and Chief Operations Officer of Village Energy, a WA born start-up that has developed energy technology to operate at the edge of the energy grid and deliver cleaner, cheaper and more reliable power to underserved populations.Yvonne led the development of a highly successful pilot project in Andhra Pradesh, India, with which WA has a sister-state relationship.Since the pilot, Village Energy has signed agreements across India, Indonesia, Vietnam and Cambodia and has a potential pipeline to millions of customer connections.{''}</h5>
                  <h5 className={classes.cardDescription}>
                    {''}Village Energy is backed by a Bill Gates chaired venture capital fund, Breakthrough Energy Ventures, and has been recognized as an APAC25 company and is the 2020 winner of the Australian Technologies Competition in energy.Yvonne is a mother of three, 2020 40 under 40 award winner, and formerly worked at Horizon Power as Manager Strategy, helping to drive the shift to renewable and distributed energy microgrids.{''}</h5>
                    </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorDanielle} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Danelle Cross</h4>
                  <Muted>
                    <h6>OWNER/DIRECTOR @ GOURMET & MORE</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Danelle Cross was appointed to the Board in July 2019. Ms Cross is co-owner/director of a small business, Gourmet and More, a gourmet food wholesaler and distributor.She currently heads up the executive education area within the Faculty of Business and Law at Curtin University and has more than 18 years’ experience in strategic marketing, communications, stakeholder relations, innovation, learning and development and program management across education, FMCG and media.</h5>
                  <h5 className={classes.cardDescription}>
                    {''}Danelle holds a Bachelor of Commerce, double major in marketing and PR from Curtin University. She is a Board Member of the Small Business Development Corporation and a mentor and connector for many WA Founders.</h5>                    
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorAmanda} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Amanda Walker</h4>
                  <Muted>
                    <h6>FOUNDER @ WA HomeStay</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Amanda Walker is the founder of WA HomeStay, an online booking platform for WA holiday accommodation. She is passionate about promoting sustainable tourism in WA and value based business practices. As a female founder of a tech startup, Amanda has overcome many of the entrepreneurial challenges startups face and is currently moving WA HomeStay to scale.</h5>                  
                  <h5 className={classes.cardDescription}>
                    {''}Amanda was named as a 2020 Business News 40 Under 40 Winner, was a finalist in the WA Tourism Awards 2019 "New Business" Category and a finalist in the INCITE "Start Up of the Year" Awards 2019. She is also an Alumni of WA Leaders.</h5>              
                </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorPaul} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Paul Morrison</h4>
                  <Muted>
                    <h6>DIRECTOR @ ELITE HOSPITALITY & CONNECT HEALTH SERVICE</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                  {''}Paul is a proven business leader across multiple businesses and industries. 40under40 winner, Finalist for RISE Business awards. He is a National judge for WorldSkills Australia.Paul's values come from growing up in Ireland and facing times of difficulty during the recession and then facing adversity – making the decision to leave Ireland and come to Australia for a better life in October 2011.
                  </h5> 
                   <h5 className={classes.cardDescription}>
                  {''}Transparency, Determination, Constant growth personally and professionally, Commitment to clients, team and goals, importance of Family, Giving back - these are at the backbone of all that he does as an individual and business owner across multiple industries. 
                  </h5>                   
                </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorHeather} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Heather Preston</h4>
                  <Muted>
                    <h6>STRATEGIC RELATIONSHIPS MANAGEMENT @ EDITH COWAN UNIVERSITY</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                  {''}Heather began her passion for entrepreneurship at a young age, running her first business at the age of nineteen. She recently completed an MBA specialising in Entrepreneurship and Innovation where she was recognised on the School of Business and Law’s 2020 Dean’s List and won the City of Wanneroo, Current Issues in Entrepreneurship and Innovation prize.                  
                  </h5> 
                   <h5 className={classes.cardDescription}>
                   {''}Heather is also a passionate advocate for LGBTQIA+ students and young professionals through her role as Chief Community Officer with Out For Australia.                                    
                   </h5>
                </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorJacqui} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Jacqui Nelson</h4>
                  <Muted>
                    <h6>CEO @ DEKKO SECURE</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Jacqui is the CEO of Dekko Secure, an Australian cyber security company that helps organisations to share, collaborate and communicate with total privacy and security. She has a passion for innovation and success and making a positive contribution to business and society. Jacqui has been closely involved in the start up and successful growth of several commercial and social enterprise ventures.                  
                   </h5>
                </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorJason} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Jason Balchand</h4>
                  <Muted>
                    <h6>COO @ SO MEDIA GROUP</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Jason is the COO of So Media Group, a fast-growing new media start-up, focused on creating compelling and engaging content for audiences across the web and social media, providing clients and brands with unique advertising and content marketing services. He is also a Board Member of Startup WA & Co-Founder of Perth Sports Network.                    
                  </h5>
                </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorEunice} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Dr Eunice Sari</h4>
                  <Muted>
                    <h6>CEO & CO-FOUNDER @ UX INDONESIA</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                  Dr. Eunice Sari is the CEO and Co-Founder of UX Indonesia, the first insight-driven UX Research, Training, and Consulting Company based in Indonesia since 2002, and co-founder of Customer Experience Insight Pty Ltd (Australia). Having more than 15 years of experience in academics and industries, she has helped many. She is also the first Asian female Google Global elite mentor in Product Design and Strategy (UX, UI)  helping startups around the world, particularly from emerging nations in Southeast Asia, Africa, Eastern Europe and Latin America.                 
                  </h5>                
                </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                  <img src={MentorAndrea} alt="..." />
                  {/* </a> */}
                </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Andrea Conte</h4>
                  <Muted>
                    <h6>MANAGING DIRECTOR @ FIRETECH AUSTRALIA</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                  Andrea is a passionate advocate for better STEM and Digital Technology education in schools and teaching children how to leverage technology from a young age. 
                  </h5>                
                </CardBody>
                </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Testimonials 3 END */}
      <Footer
        content={
          <div>
            <div className={classes.left}>
              {/* <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/home" className={classes.block}>
                    Home
                  </Link>
                </ListItem>{" "}
                <ListItem className={classes.inlineBlock}>
                  <Link to="/teams" className={classes.block}>
                    Teams
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/blog-posts" className={classes.block}>
                    Blogs
                  </Link>
                </ListItem>
              </List> */}
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} Made by{" "}
              <a href="http://www.franked.io" target="_blank">
                franked.io
              </a>{" "}
              for a kinder web.
            </div>
          </div>
        }
      />
    </div>
  );
}