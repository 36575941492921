import React from 'react';
import { Link } from 'react-router-dom';

// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite';
// core components
import Header from 'components/Header/Header.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Parallax from 'components/Parallax/Parallax.js';
import Footer from 'components/Footer/Footer.js';
// sections for this page
import HeaderLinks from 'components/Header/HeaderLinks.js';
import SectionDescription from 'views/ParticipantFAQPage/Sections/SectionDescription.js';
import SectionTeam from 'views/AboutUsPage/Sections/SectionTeam.js';
import SectionServices from 'views/ParticipantFAQPage/Sections/SectionServices.js';
import SectionSchedule from 'views/ParticipantFAQPage/Sections/SectionSchedule.js';
import SectionOffice from 'views/AboutUsPage/Sections/SectionOffice.js';
import SectionContact from 'views/ParticipantFAQPage/Sections/SectionContact.js';
// import SectionOverview from "views/AboutUsPage/Sections/SectionOverview.js";

import aboutUsStyle from 'assets/jss/material-kit-pro-react/views/aboutUsStyle.js';

const useStyles = makeStyles(aboutUsStyle);

export default function ParticipantFAQPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        // brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: 'info',
        }}
      />
      <Parallax image={require('assets/img/AboutUs.jpg')} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Information for Participants</h1>
              <h4>We can't wait for you to get started.</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionDescription />
          {/* <SectionTeam /> */}
          <SectionServices />
          {/* <SectionSchedule /> */}
          {/* <SectionOverview /> */}
          {/* <SectionOffice /> */}
          {/* <SectionContact /> */}
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/home" className={classes.block}>
                    Home
                  </Link>
                </ListItem>{' '}
                <ListItem className={classes.inlineBlock}>
                  <Link to="/participant-information" className={classes.block}>
                    Participant Information
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/blog-posts" className={classes.block}>
                    Blogs
                  </Link>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} Made by{' '}
              <a href="http://www.franked.io" target="_blank">
                franked.io
              </a>{' '}
              for a kinder web.
            </div>
          </div>
        }
      />
    </div>
  );
}
