import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// nodejs library that concatenates classes
import classNames from 'classnames';

// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Schedule from '@material-ui/icons/Schedule';
import Message from '@material-ui/icons/Message';
import Assessment from '@material-ui/icons/Assessment';
import List from '@material-ui/icons/List';
import ListItem from '@material-ui/core/ListItem';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import NavPills from 'components/NavPills/NavPills.js';
import pillsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/pillsStyle.js';

const useStyles = makeStyles(pillsStyle);

export default function SectionSchedule() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem
          md={12}
          sm={12}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>
            Joondalup Innovation Challenge Schedule of Events
          </h2>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <NavPills
            color="info"
            tabs={[
              {
                tabButton: 'Day 1: 8th September 2022',
                tabIcon: Dashboard,
                tabContent: (
                  <span>
                    <p>
                      <strong>LEARNING MODE:</strong> Virtual Orientation Day on
                      Zoom.com
                    </p>
                    <p>
                      <strong>ATTENDANCE:</strong> Attendance is compulsory.
                      Failure to attend this event without prior notification
                      will make you ineligible to continue with the program
                    </p>
                    <p>
                      <strong>TIMEZONE:</strong> Perth Australia time zone
                    </p>
                    <p>
                      10.30am Program Welcome: Facilitator introduction & Agenda{' '}
                      <br />
                      10.45am Teambuilding Icebreaker activity 
                      <br />
                      11.05am JOONDALUP INNOVATION CHALLENGE (JIC) - 4 day
                      agenda & outcomes. Introduction to JIC challenge problems
                      to solve <br />
                      11.15am Important information about your team and mentor.
                      Invitation to join Slack and LinkedIn channels as well as
                      tech setup requirements. Introduction to video resources
                      available to you <br />
                      11.45am Learn the secrets of high performing teams. Learn
                      strategies on how to build a strong team for JIC <br />
                      12.30pm Wrap up and close
                    </p>
                    <br />
                    <p>
                      <strong>
                        WHAT HAPPENS AFTER THE ORIENTATION DAY 1 SUMMIT
                        COMPLETES?{' '}
                      </strong>
                    </p>
                    <p>
                      1){' '}
                      <a href="http://franked.io" target="new window">
                        franked.io
                      </a>{' '}
                      will email you the contact details of your other team
                      members and mentor. You are responsible for getting in
                      touch with them asap and setting up a time to meet.
                    </p>
                    <p>
                      2) You should also setup your team communication channel
                      e.g. Whatsapp group, FB messenger, Slack
                    </p>
                    <p>
                      3) You should login and watch the 2 video courses that{' '}
                      <a href="http://franked.io" target="new window">
                        franked.io
                      </a>{' '}
                      will give you access to; Introduction to Entrepreneurship
                      and Teamwork Skills. These courses go over the content
                      covered in JIC and by watching it before JIC kicks off;
                      you give yourself an advantage.
                    </p>
                    <p>
                      <strong>
                        IS THERE ANYTHING DUE FOR SUBMISSION AFTER THIS
                        ORIENTATION DAY 1?{' '}
                      </strong>
                    </p>
                    <p>
                      Yes, you and your team will need to submit your chosen
                      challenge problem,your team name and team logo to us by{' '}
                      <strong>5pm 12th September 2022.</strong> We will
                      introduce these challenge problems to you at the Virtual
                      Orientation Day on 8th September 2022.
                    </p>
                  </span>
                ),
              },
              {
                tabButton: 'Day 2: 13th September 2022',
                tabIcon: Schedule,
                tabContent: (
                  <span>
                    <p>
                      <strong>LEARNING MODE:</strong> Virtual Summit on Zoom.com
                      <br />
                    </p>
                    <p>
                      <strong>ATTENDANCE:</strong> Attendance is compulsory.
                    </p>
                    <p>
                      <strong>TIMEZONE:</strong> Perth Australia time zone
                    </p>
                    <p>
                      10.30am Program welcome, facilitator introduction & agenda
                      for Day 2 <br />
                      10.45am Welcome by CEO,City of Joondalup 
                      <br />
                      11.00am Networking activities <br />
                      11.20am Meditation and mindfulness with a special guest{' '}
                      <br />
                      11.30am JIC Program outcomes and key information.
                      Introduction to JIC judges <br />
                      11.55am Inspiring Keynote Speaker <br />
                    </p>
                    <p>12.15pm SHORT BREAK</p>
                    <p>
                      12.30pm Introduction to entrepreneurship, start-ups and
                      growth mindset <br />
                      12.50pm Guest Presentations on Challenge Themes <br />
                      1.20pm Masterclasses: Introduction to the Business Model Canvas
                      with <a href="http://franked.io" target="new window">
                        franked.io
                      </a> facilitators covering topics such as
                      customer segments & value proposition <br />
                      2.20pm Reflections & preparing for Day 3 <br />
                      2.30pm Summit close <br />
                    </p>
                    {/* <p>
                            1.30pm LUNCH & CATCHUP WITH YOUR TEAM (including virtual team members)  < br />
                        </p>
                        <p>
                            2.15pm Innovation tours begin in Joondalup and Perth areas. Though attendance at these tours is not compulsory, it is highly recommended to help you understand your challenge problem better <br />
                            5.45pm Innovation tours complete
                        </p> */}
                    <br />
                    <p>
                      <strong>
                        WHAT HAPPENS AFTER THE DAY 2 SUMMIT COMPLETES?{' '}
                      </strong>
                    </p>
                    <p>
                      1) You and your team should keep communicating and
                      discussing your idea and continue to develop it further
                      for the remainder of the day. You will need to delegate
                      tasks in your team as time is very tight
                    </p>
                    <p>
                      2) You should arrange to meet with your mentor for any
                      guidance on your idea development
                    </p>
                    <br />
                    <p>
                      <strong>
                        IS THERE ANYTHING DUE FOR SUBMISSION AFTER DAY 2?{' '}
                      </strong>
                    </p>
                    <p>
                      No, there is nothing due for submission. But use this time
                      to keep planning and developing your idea.
                    </p>
                  </span>
                ),
              },
              {
                tabButton: 'Day 3: 14th September 2022',
                tabIcon: List,
                tabContent: (
                  <span>
                    <p>
                      <strong>LEARNING MODE:</strong> Virtual Summit on Zoom.com
                      <br />
                    </p>
                    <p>
                      <strong>ATTENDANCE:</strong> Attendance is compulsory.
                    </p>
                    <p>
                      <strong>TIMEZONE:</strong> Perth Australia time zone
                    </p>
                    <p>
                      10.30am Welcome & agenda. Shared learnings from Day 2{' '}
                      <br />
                      10.55am Meditation and mindfulness with a special guest{' '}
                      <br />
                      11.10am Inspiring Keynote Speaker - David Pettit, Founder
                      PictureWealth <br />
                    </p>
                    <p>11.30am SHORT BREAK</p>
                    <p>
                      11.50am Masterclasses: Introduction to the Business Model
                      Canvas with{' '}
                      <a href="http://franked.io" target="new window">
                        franked.io
                      </a>{' '}
                      facilitators covering topics such as channels,costs and
                      revenue <br />
                      1.30pm Learn how to create a video pitch <br />
                      2.30pm Summit close <br />
                    </p>
                    <br />
                    <p>
                      <strong>
                        WHAT HAPPENS AFTER THE DAY 3 SUMMIT COMPLETES
                      </strong>
                    </p>
                    <p>
                      1) You and your team should keep communicating and
                      discussing your idea and developing it further for the
                      remainder of the day. You will need to delegate tasks in
                      your team as time is very tight
                    </p>
                    <p>
                      2) You should arrange to meet with your mentor for any
                      guidance on your idea development
                    </p>
                    {/* <p>
                            3) Some of your team members will continue to do surveys and customer interviews to get feedback on your idea.
                        </p> */}
                    <p>
                      <strong>
                        IS THERE ANYTHING DUE FOR SUBMISSION AFTER THIS DAY 3
                        SUMMIT?{' '}
                      </strong>
                    </p>
                    <p>
                      Yes, you and your team will need to submit your 3 minute
                      video pitch of your idea and your Powerpoint slidedeck to
                      us by <strong>12pm 20th September 2022.</strong> We will
                      show you how to create a video pitch and what to include
                      in it at the Day 3 Summit itself.
                    </p>
                  </span>
                ),
              },
              {
                tabButton: 'VIDEO PITCH DUE: 20th September 2022',
                tabIcon: Message,
                tabContent: (
                  <span>
                    <p>
                      <strong>TIMEZONE:</strong> Perth Australia time zone
                    </p>
                    <h2>
                      <strong>VIDEO PITCH</strong>
                    </h2>
                    <p>
                      Your team is responsible for submitting a 3 minute video
                      pitch and your 5 slide Powerpoint deck to us by{' '}
                      <strong>12pm 20th September 2022.</strong> We will show
                      you how to create a video pitch and what to include in it
                      at the Day 3 Virtual Summit <br />
                    </p>
                    <p>
                      <strong>
                        AFTER YOU SUBMIT YOUR VIDEO PITCH: PREPARE FOR YOUR
                        HYBRID PITCH EVENT WHICH KICKS OFF AT 5PM ON 22ND
                        SEPTEMBER 2022{' '}
                      </strong>
                    </p>
                    <p>
                      Your team will need to nominate and prepare 1-2 speakers
                      from your team to pitch your idea at the Pitch Event on
                      22nd September 2022. These speakers MUST attend in-person{' '}
                      <br />
                    </p>
                    <p>
                      This pitch will be 3 minutes long and cover the same
                      content as the video pitch. All remote and offshore
                      participants will be able to attend this event via a
                      livestream. <br />
                    </p>
                    <p>
                      Your team can use a 5 slide Powerpoint in your Live Pitch
                      <br />
                    </p>
                  </span>
                ),
              },
              {
                tabButton: 'DAY 4 LIVE PITCH: 22ND September 2022',
                tabIcon: Message,
                tabContent: (
                  <span>
                    <h2>
                      <strong>LIVE PITCH</strong>
                    </h2>
                    <p>
                      <strong> LEARNING MODE:</strong> Pitch Event (In-person
                      event for students in Australia. For students outside
                      Australia, you will join this event on Zoom.com via a
                      livestream)
                      <br />
                    </p>
                    <p>
                      <strong>VENUE:</strong> Conference Room, Joondalup
                      Reception Centre, 102 Boas Ave, Joondalup WA 6027,
                      Australia
                    </p>
                    <p>
                      <strong>ATTENDANCE:</strong> Attendance is compulsory.
                    </p>
                    <p>
                      <strong>TIMEZONE:</strong> Perth Australia time zone
                    </p>
                    <p>
                      5.00pm Welcome & agenda for pitch event <br />
                      5.15pm Attendance roll-call for students who will be
                      pitching and representing their team
                      <br />
                      5.25pm{' '}
                      <a href="http://franked.io" target="new window">
                        franked.io
                      </a>{' '}
                      Facilitators will deliver smaller masterclass sessions to
                      provide tips and practice for pitching
                      <br />
                    </p>
                    <p>6.00pm BREAK</p>
                    <p>
                      6.30pm Official pitch event welcome and agenda.
                      Introduction to the 3 judges and the Joondalup Innovation
                      Challenge journey <br />
                      6.45pm Welcome Remarks
                      <br />
                      6.50pm Pitching commences: 1 -2 speakers from each of the
                      5 teams will pitch their idea 1 by 1 to the 3 judges
                      <br />
                    </p>
                    <p>
                      7.30pm SHORT BREAK FOR GUESTS (Judges will deliberate
                      during this time and students complete online evaluations){' '}
                      <br />
                    </p>
                    <p>
                      7.50pm Winners announced <br />
                      8.00pm Final words and thank-yous <br />
                    </p>
                  </span>
                ),
              },
            ]}
          />
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={8} lg={6}>
              <NavPills
                color="primary"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 3, md: 4 },
                  contentGrid: { xs: 12, sm: 9, md: 8 }
                }}
                tabs={[
                  {
                    tabButton: "Dashboard",
                    tabIcon: Dashboard,
                    tabContent: (
                      <span>
                        <p>
                          Collaboratively administrate empowered markets via
                          plug-and-play networks. Dynamically procrastinate B2C
                          users after installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                      </span>
                    )
                  },
                  {
                    tabButton: "Schedule",
                    tabIcon: Schedule,
                    tabContent: (
                      <span>
                        <p>
                          Efficiently unleash cross-media information without
                          cross-media value. Quickly maximize timely
                          deliverables for real-time schemas.
                        </p>
                        <br />
                        <p>
                          Dramatically maintain clicks-and-mortar solutions
                          without functional solutions. Dramatically visualize
                          customer directed convergence without revolutionary
                          ROI. Collaboratively administrate empowered markets
                          via plug-and-play networks. Dynamically procrastinate
                          B2C users after installed base benefits.
                        </p>
                      </span>
                    )
                  }
                ]}
              />
            </GridItem> */}
      </GridContainer>
    </div>
  );
}
