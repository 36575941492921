const TeamsData2021 = [
  {
    id: 1,
    name: 'Next Gen ',
    motto: '',
    image: require('assets/img/teamLogos2021/team1.png'),
    url: 'https://www.youtube.com/watch?v=jDpn3Wyh5_M',
  },
  {
    id: 2,
    name: 'Imprints',
    motto: '2021 WINNER',
    image: require('assets/img/teamLogos2021/team2.png'),
    url: 'https://www.youtube.com/watch?v=DiaiBREuJ1A',
  },
  {
    id: 3,
    name: 'Joondalism',
    motto: '',
    image: require('assets/img/teamLogos2021/team3.png'),
    url: 'https://www.youtube.com/watch?v=7INDQaXbjzI',
  },
  {
    id: 4,
    name: 'Created Brains',
    motto: '',
    image: require('assets/img/teamLogos2021/team4.jpg'),
    url: 'https://www.youtube.com/watch?v=0CE40A6DPQk',
  },
  {
    id: 5,
    name: 'Metsa',
    motto: '',
    image: require('assets/img/teamLogos2021/team5.jpg'),
    url: 'https://www.youtube.com/watch?v=eeOd4iFheog',
  },
  {
    id: 6,
    name: 'CHIP',
    motto: '',
    image: require('assets/img/teamLogos2021/team6.png'),
    url: 'https://www.youtube.com/watch?v=WoVgVZeJ7ug',
  },
  {
    id: 7,
    name: 'IntrLynk',
    motto: 'Top 5',
    image: require('assets/img/teamLogos2021/team7.jpg'),
    url: 'https://youtu.be/CUxjvFMDAYY',
  },
  {
    id: 8,
    name: 'The Whimsical Crew',
    motto: '',
    image: require('assets/img/teamLogos2021/team8.jpg'),
    url: 'https://www.youtube.com/watch?v=J0GgSapI4Fo',
  },
  {
    id: 9,
    name: 'Goal Diggers',
    motto: 'Top 5',
    image: require('assets/img/teamLogos2021/team9.png'),
    url: 'https://www.youtube.com/watch?v=0mGYG8VaxXI',
  },
  {
    id: 11,
    name: 'Metanative',
    motto: '2021 2ND RUNNER UP',
    image: require('assets/img/teamLogos2021/team11.png'),
    url: 'https://www.youtube.com/watch?v=1QqOiOgON0I',
  },
  {
    id: 12,
    name: 'Cygenix',
    motto: '',
    image: require('assets/img/teamLogos2021/team12.png'),
    url: 'https://www.youtube.com/watch?v=U_2TVeoeNio',
  },
  {
    id: 13,
    name: 'Advance 6.0',
    motto: '',
    image: require('assets/img/teamLogos2021/team13.png'),
    url: 'https://www.youtube.com/watch?v=KinDzZioMqc',
  },
  {
    id: 14,
    name: 'BizCov',
    motto: '',
    image: require('assets/img/teamLogos2021/team14.png'),
    url: 'https://www.youtube.com/watch?v=fXVBVws-bU8',
  },
  {
    id: 15,
    name: 'GLUE',
    motto: '',
    image: require('assets/img/teamLogos2021/team15.png'),
    url: 'https://www.youtube.com/watch?v=BCMS62aflYs',
  },
  {
    id: 16,
    name: 'EazeTech',
    motto: '',
    image: require('assets/img/teamLogos2021/team16.png'),
    url: 'https://www.youtube.com/watch?v=LfOv6RbNfO0',
  },
  {
    id: 17,
    name: 'Windmill',
    motto: '',
    image: require('assets/img/teamLogos2021/team17.png'),
    url: 'https://www.youtube.com/watch?v=UtW3TtzSmCE',
  },
  {
    id: 18,
    name: 'Joondalup Guardian Angels',
    motto: '',
    image: require('assets/img/teamLogos2021/team18.png'),
    url: 'https://www.youtube.com/watch?v=kq8zecqRnLI',
  },
  {
    id: 19,
    name: 'AlterGizmo',
    motto: '',
    image: require('assets/img/teamLogos2021/team19.png'),
    url: 'https://www.youtube.com/watch?v=lYagbaTmUVU',
  },
  {
    id: 20,
    name: 'StepUP',
    motto: '',
    image: require('assets/img/teamLogos2021/team20.png'),
    url: 'https://www.youtube.com/watch?v=E7ZvweF1ATA',
  },
  {
    id: 21,
    name: 'Melting Pot',
    motto: '',
    image: require('assets/img/teamLogos2021/team21.jpg'),
    url: 'https://www.youtube.com/watch?v=xWvlPiqQtU0',
  },
  {
    id: 22,
    name: 'EVA',
    motto: '2021 1ST RUNNER UP',
    image: require('assets/img/teamLogos2021/team22.jpg'),
    url: 'https://www.youtube.com/watch?v=I8sp3mhE6jg',
  },
  {
    id: 23,
    name: 'J Trails',
    motto: '',
    image: require('assets/img/teamLogos2021/team23.png'),
    url: 'https://youtu.be/C2GkfVBA4bA',
  },
];
export default TeamsData2021;
