export const MentorsData = {
  2024: [
    {
      id: 1,
      name: 'Heather Preston',
      imageURL: require('assets/img/faces/mentorfaces/Heather Preston.jpg'),
      mutedText:
        'Interim CEO @ Out For Australia & Change Management Specialist',
      description:
        "Heather began her passion for entrepreneurship at a young age, running her first business at the age of nineteen. She holds an MBA specialising in Entrepreneurship and Innovation where she was recognised on the School of Business and Law's 2020 Dean's List and won the City of Wanneroo, Current Issues in Entrepreneurship and Innovation prize. Heather is a passionate advocate for LGBTQIA+ students and young professionals through her role with Out For Australia. She also works as a Change Management Specialist in the Public Sector, supporting projects to successfully implement new ways of working.",
    },
    {
      id: 2,
      name: 'Greg	Rogers',
      imageURL: require('assets/img/faces/mentorfaces/Greg Rogers.png'),
      mutedText: 'Coaching | Training | Consulting',
      description:
        'Greg helps business owners when they are in a state of overwhelm. start-up business owners who are STALLED hamster-wheel business owners who are STUCK ‘in’ the business instead of working ‘on’ the business and seasoned, established businesses who want to SCALE. Greg is the guy to turn to when you need help; to get started (clarity) get it implemented (process) and get the outcome (result) you want. Oh…and he’s a self-confessed ‘foodine’ (lover of food and wine) and sports nut to boot!',
    },
    {
      id: 3,
      name: 'Rael	Bricker',
      imageURL: require('assets/img/faces/mentorfaces/Rael Bricker.jpg'),
      mutedText:
        'International Keynote Speaker, Mentor, Facilitator, Author and Coach @ The Business Excellence Guy',
      description:
        'As a seasoned entrepreneur who turned a health scare into motivation, Rael leverages his diverse experiences from finance to education, on two continents and two listed companies. He serve businesses globally, having navigated challenges from starting companies to managing billions in loans. His partnerships are a  blend of real-world experience with academic qualifications  (MBA, MSc) to guide people and companies towards excellence. From being 6000ft underground in a gold mine, to starting an education business (that grew to have 4000 plus students) to spending years working in venture capital, Rael Bricker has seen it all. He has listed companies on multiple international stock exchanges, and his financial services group has settled more than $3bn in loans over 20 years. Rael has the unique distinction of having sold more than $1bn in mortgages from stage. Rael’s diverse work history combined with unique global research interviews with companies in more than 25 countries allows him to work with leaders and managers on growing and achieving excellence, as he has experienced the rollercoaster himself and knows how to navigate the twists, turns and loops. If you need Rael to have academic qualifications, he holds two Masters Degrees: an MBA and an MSc (Software Engineering). He is currently a Fellow of the MFAA (Mortgage and Finance Association of Australia), a Certified Speaking Professional  (CSP)  (Professional Speakers Australia) , Past Chapter President of PSA (WA) and a Member of AICD (Australian Institute of Company Directors). In 2018, Rael published “Dive in – Lessons learnt since Business School”',
    },
    // {
    //   id: 4,
    //   name: 'Silvana	Macri',
    //   imageURL: require('assets/img/faces/mentorfaces/Silvana Macri.jpg'),
    //   mutedText: 'Founder @ Stay Cyber Safe',
    //   description:
    //     'Creating Cyber Resilience in our People through a transformation in Culture. Protecting information by building human firewalls. Solving problems with diverse perspectives. Well experienced, qualified & regarded in the international cyber security community. Focused on building organisational cyber security strengths in recent roles in health, insurance, financial services and critical infrastructure. I understand cyber and how to prevent, plan for and best handle a cyber attack to minimise impact. I am an active member of AISA (past committee member), WiTWA (past committee member) & AWSN. Proud to be partnering with Gartner/Forrester leaders &  AustCyber to successfully deliver best cyber resilient enterprise cultures in Western Australian. 15 years in Canberra, Sydney and Melbourne before returning to Perth saw me season my craft. With a "highly protected" security clearance I supported a number of Ministers and Executives in building a strategy to secure their assets.',
    // },
    {
      id: 5,
      name: 'Karen Dennett',
      imageURL: require('assets/img/faces/mentorfaces/Karen Dennett.png'),
      mutedText:
        'Director @ Future Female Leaders Program, Business Mentor, and Author',
      description:
        'The perfectly imperfect combination of entrepreneurship and teaching, set on a marketing degree base and seasoned with neuro spice. Entrepreneur, Mentor, Mum, Founding Director of the Future Female Leaders Program, Teacher, Author, Certified Entrepreneurial Mindset Profile Practitioner and proud Gen X woman. I am a passionate, purpose-driven, creative and strategic thinker who loves to challenge the status quo and create pioneering solutions to problems. I empower others to find and share their value, through mentoring, learning, connection and opportunities to shine.',
    },
    {
      id: 6,
      name: 'Jin S Yoon',
      imageURL: require('assets/img/faces/mentorfaces/Jin S Yoon.jpeg'),
      mutedText: 'Entrepreneur/Career Mentor & Coach',
      description:
        'As an entrepreneur, mentor-coach, and consultant for startups, I bring valuable expertise in business development, online marketing strategy, and innovation. I am passionate about discovering groundbreaking ideas, products, and services, and setting up enterprises that deliver innovative solutions. My goal is to be part of and inspire vibrant entrepreneurial communities to support and foster growth.',
    },
    {
      id: 7,
      name: 'Dayna Bennett',
      imageURL: require('assets/img/faces/mentorfaces/Dayna Bennett.jpg'),
      mutedText: 'Business Development Manager @ Red Piranha',
      description:
        'Spending majority of her career within the International Education Sector, Dayna has managed student support teams and all-encompassing portfolios including International Education Compliance and Global Funding Programs. In addition, transferring her international education knowledge over to the Insurance sector, leading state territories B2B and B2C Business Development and Account Management, with proven retention and profitability results. Dayna’s capacity to build and nurture key relationships whilst driving strategic direction and adhering to legislative governance has ensured ongoing successful partnerships throughout her career whilst being invited to present at various ANZ Forums and Associations. Since starting at Red Piranha, a Perth-born cybersecurity company, now with global presence, Dayna has extensive experience in project management initiatives around onboarding new technologies, delivering outcomes and training.',
    },
    {
      id: 8,
      name: 'Glenn Murray',
      imageURL: require('assets/img/faces/mentorfaces/Glenn Murray.jpeg'),
      mutedText: 'Managing Director @ PWC',
      description:
        'A professional, dynamic, results-driven Chief Executive Officer/ Managing Director with 20+ years of professional leadership experience, gained in military, government and private sectors. Glenn has a proven history at developing and strengthening multiple disciplined teams and obtaining commercial results, moving companies to the next level of development, including start-up companies. Glenn has lead teams through transformation in challenging environments, bringing a robust focus on long term strategy and associated risk. Glenn is an accomplished executive with extensive experience in leading multi-million dollar projects in ASX listed companies. Primarily focused in the application of information, communications, technology and cybersecurity solutions across the oil and gas, public, mining, heavy vehicle manufacturing, mining, defence (Electronic Warfare) and telecommunication industries. Glenn also has had significant “hands on” experience, design, architecting cybersecurity solutions.',
    },
    {
      id: 9,
      name: 'Ryan Coble-Neal',
      imageURL: require('assets/img/faces/mentorfaces/Ryan Coble-Neal.jpg'),
      mutedText: 'Founder , Camplus',
      description:
        'Ryan is a recent graduate and the founder of Camplus, a student experience app that helps students succeed and reduce workload for staff at universities. Camplus was the winning pitch from JIC 2022.',
    },
    {
      id: 10,
      name: 'Paul Cannavan',
      imageURL: require('assets/img/faces/mentorfaces/Paul Cannavan.jpeg'),
      mutedText: 'Product Consultant, Founder & Startup Mentor',
      description:
        'Paul is a Product Consultant, Founder, and Startup Mentor with over 20 years of experience developing digital solutions for businesses and startups across Australia and the UK. He is passionate about making a positive impact by building products that truly make a difference, combining empathy, innovation, and strategy to deliver real value. Paul provides consulting services to startups, mid-sized organizations, and agencies, helping them solve problems, craft strategies for success, and achieve vision-driven outcomes. He uses a problem-led approach, leveraging design workshops, customer validation, and agile development techniques to shape product strategies and business models with a clear focus on results. His expertise spans marketplace solutions and health apps, as well as mentoring startups through Spacecubed, where he helps transform concepts into successful, scalable products that deliver tangible outcomes for users and stakeholders.',
    },
  ],
};
