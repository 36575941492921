import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import descriptionStyle from 'assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js';

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h5 className={classes.description}>
            The JOONDALUP INNOVATION CHALLENGE is co-created by City of
            Joondalup and The{' '}
            <a href="https://franked.io" target="_blank">
              franked.io
            </a>{' '}
            Team. As a participant, you are participating in WA's foremost
            innovation challenge. Prepare yourself for the Jobs of the Future
            and master teamwork and communication skills, build your
            entrepreneurial and innovation thinking, work with an industry
            mentor and learn from young Australian and global entrepreneurs.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
