const TeamsData = [
  {
    id: 1,
    name: 'Diversiform',
    motto:
      'Transforming challenges into solutions! (This idea is now being commercialised)',
    image: require('assets/img/teamLogos/Team1.png'),
    url: 'https://enews.joondalup.wa.gov.au/v.aspx?n=2890',
  },
  {
    id: 2,
    name: 'Mylk',
    motto: 'The New Normal',
    image: require('assets/img/teamLogos/Team2.png'),
    url: 'https://youtu.be/20QXdfe01Qo',
  },
  {
    id: 3,
    name: 'Team Tree',
    motto: 'ideas to solve the root of problems',
    image: require('assets/img/teamLogos/Team3.png'),
    url: 'https://www.youtube.com/watch?v=w-3Y0E1EwTs&feature=youtu.be',
  },
  {
    id: 4,
    name: 'Jam Jar',
    motto: 'Unjamming problems since 2020',
    image: require('assets/img/teamLogos/Team4.png'),
    url: 'https://www.youtube.com/watch?v=eZ1O_5n3m0o',
  },
  {
    id: 5,
    name: 'Banksia Wisdom',
    motto: "Building on Nature's Wisdom",
    image: require('assets/img/teamLogos/Team5.jpg'),
    url: 'https://www.youtube.com/watch?v=Eyw_FAemRhE&feature=youtu.be',
  },
  {
    id: 6,
    name: 'Phoenix Uniting Network (PUN)',
    motto: 'We strive to achieve a better tomorrow',
    image: require('assets/img/teamLogos/Team6.jpg'),
    url: 'https://youtu.be/cbDdrXyM92k',
  },
  {
    id: 7,
    name: 'WASTE SAVERS',
    motto: 'Make Haste Not Waste',
    image: require('assets/img/teamLogos/Team7.png'),
    url: 'https://youtu.be/6KmwoyBMR3Q',
  },
  {
    id: 8,
    name: 'JET (Joondalup Employment Team)',
    motto: 'Joon-up… your future vision is our top priority',
    image: require('assets/img/teamLogos/Team8.png'),
    url: 'https://www.youtube.com/watch?v=3o1ksD7E1dw',
  },
  {
    id: 9,
    name: 'Peak Innovation',
    motto: 'Ad Apicem – Reach the Peak',
    image: require('assets/img/teamLogos/Team9.jpg'),
    url: 'https://youtu.be/8R_lbmfzuaE',
  },
  {
    id: 10,
    name: 'Connect Seven',
    motto: 'Together our differences make us stronger',
    image: require('assets/img/teamLogos/Team10.png'),
    url: 'https://youtu.be/pvFKU7YFGuo',
  },
  {
    id: 11,
    name: '711',
    motto: 'Powering through Problems',
    image: require('assets/img/teamLogos/Team11.png'),
    url: 'https://youtu.be/nSFCXXJJsjM',
  },
  {
    id: 12,
    name: 'Simple Six',
    motto: 'Six simple minds can change the world',
    image: require('assets/img/teamLogos/Team12.png'),
    url: 'https://youtu.be/pSjmtHqYisk',
  },
  {
    id: 13,
    name: 'New Innovative Alliance.',
    motto: 'Open the box, shape the future!',
    image: require('assets/img/teamLogos/Team13.png'),
    url: 'https://youtu.be/cm6adahr8tw',
  },
  {
    id: 14,
    name: 'The Romans',
    motto: "Mediators of community,Rome wasn't built in a day",
    image: require('assets/img/teamLogos/Team14.jpg'),
    url: 'https://youtu.be/-4o2G7oM2WQ',
  },
  {
    id: 15,
    name: 'CANDO',
    motto: 'Innovation leads to creation.',
    image: require('assets/img/teamLogos/Team15.png'),
    url: 'https://youtu.be/dvTL1GfWQqk',
  },
  {
    id: 16,
    name: 'You-Knight',
    motto: 'Building stronger communities together',
    image: require('assets/img/teamLogos/Team16.png'),
    url: 'https://youtu.be/X13cE6uvLrQ',
  },
  {
    id: 17,
    name: 'GEN.Z',
    motto: 'Innovation Needs Collaboration',
    image: require('assets/img/teamLogos/Team17.PNG'),
    url: 'https://youtu.be/I3nQst_u1Ns',
  },
  {
    id: 18,
    name: 'MaGNeT',
    motto: 'Conductive.Creative.Cohesive',
    image: require('assets/img/teamLogos/Team18.png'),
    url: 'https://www.youtube.com/watch?v=sWf09Njvka4&feature=youtu.be',
  },
  {
    id: 19,
    name: 'Murroo',
    motto: 'Dream Aspire Success',
    image: require('assets/img/teamLogos/Team19.jpg'),
    url: 'https://youtu.be/30s1hfKmIR8',
  },
  {
    id: 20,
    name: 'Lumos',
    motto: 'We are all meant to shine.',
    image: require('assets/img/teamLogos/Team20.png'),
    url: 'https://youtu.be/lsja877dMbM',
  },
  {
    id: 21,
    name: 'CIPHER',
    motto: 'Together, we secure',
    image: require('assets/img/teamLogos/Team21.png'),
    url: 'https://www.youtube.com/watch?v=FymIEjPjC8o&amp;feature=youtu.be',
  },
  {
    id: 22,
    name: 'Titans',
    motto: 'the incarnation of natural strength',
    image: require('assets/img/teamLogos/Team22.PNG'),
    url: 'https://www.youtube.com/watch?v=5oU9xssg3cM&feature=youtu.be',
  },
  {
    id: 23,
    name: 'Infinitus',
    motto: 'Infinitus: Possibilities Galore',
    image: require('assets/img/teamLogos/Team23.png'),
    url: 'https://youtu.be/Hr10qLSDOVo',
  },
  {
    id: 24,
    name: 'Kaleidoscope Innovations',
    motto: 'Leading you to new destinations',
    image: require('assets/img/teamLogos/Team24.jpg'),
    url: 'https://www.youtube.com/watch?v=6tJgJ2QGbcY&feature=youtu.be',
  },
  {
    id: 25,
    name: 'Innovative Assemblage',
    motto: 'Ideas into Practice',
    image: require('assets/img/teamLogos/Team25.PNG'),
    url: 'https://www.youtube.com/watch?v=i8IxQxd4XZQ',
  },
  {
    id: 26,
    name: 'Scientia',
    motto: 'Empowering People With Confidence',
    image: require('assets/img/teamLogos/Team26.png'),
    url: 'https://youtu.be/ewymfhieNkE',
  },
  {
    id: 27,
    name: 'DiscoverDaLup',
    motto: '1 Goal. 1 City. Infinite Possibilities.',
    image: require('assets/img/teamLogos/Team27.jpg'),
    url: 'https://youtu.be/-pArObdjmps',
  },
  {
    id: 28,
    name: 'Benang',
    motto: 'Tomorrow Together',
    image: require('assets/img/teamLogos/Team28.png'),
    url: 'https://youtu.be/bye1ftFAkoU',
  },
];
export default TeamsData;
