import React from 'react';
// import { Link } from 'react-router-dom';
// react component for creating beautiful carousel
// import Carousel from 'react-slick';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// import FormatQuote from '@material-ui/icons/FormatQuote';
// import Star from '@material-ui/icons/Star';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import Muted from 'components/Typography/Muted.js';
import Footer from 'components/Footer/Footer.js';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';

// Mentor Image
// import MentorDawnRussell from 'assets/img/faces/MentorDawnRussell.jpg';
// import MentorErin from 'assets/img/faces/MentorErin.jpg';
// import MentorJon from 'assets/img/faces/MentorJon.jpg';
// import MentorEvelyn from 'assets/img/faces/MentorEvelyn.JPG';
// import MentorJosh from 'assets/img/faces/MentorJosh.jpg';
// import MentorPhil from 'assets/img/faces/MentorPhil.jpg';
// import MetorRael from 'assets/img/faces/MetorRael.jpg';
// import MentorTuguy from 'assets/img/faces/MentorTuguy.jpg';
// import MentorBrendan from 'assets/img/faces/MentorBrendan.jpg';
// import MentorAmanda from 'assets/img/faces/MentorAmanda.jpeg';
// import MentorGreg from 'assets/img/faces/MentorGreg.jpg';
// import MentorScott from 'assets/img/faces/MentorScott.jpg';
// import MentorJiaranai from 'assets/img/faces/MentorJiaranai.jpg';
// import MentorYvonne from 'assets/img/faces/MentorYvonne.jpg';
// import MentorJacqui from 'assets/img/faces/MentorJacqui.jpeg';
// import MentorEunice from 'assets/img/faces/MentorEunice.jpeg';
// import MentorGeorge from 'assets/img/faces/MentorGeorge.jpg';
// import MentorHeather from 'assets/img/faces/MentorHeather.jpeg';
// import MentorGlennMurray from 'assets/img/faces/MentorGlennMurray.jpg';
// import MentorJinSYoon from 'assets/img/faces/MentorJinSYoon.jpeg';
// import MentorFranMorris from 'assets/img/faces/MentorFranMorris.jpg';
// import MentorAntonPemmer from 'assets/img/faces/MentorAntonPemmer.jpg';
// import Woman from 'assets/img/faces/Woman.png';

//CSS
import testimonialsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js';
import { MentorsData } from './MentorsData';
// import aboutUsStyle from 'assets/jss/material-kit-pro-react/views/aboutUsStyle.js';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const useStyles = makeStyles(testimonialsStyle);

export default function Mentors2022Page({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div className="cd-section" {...rest}>
      <div>
        <Header
          // brand="Material Kit PRO React"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="info"
          changeColorOnScroll={{
            height: 300,
            color: 'info',
          }}
        />
      </div>
      {/* Testimonials 3 START */}
      <div className={classes.testimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                Meet Some of our Amazing Mentors
              </h2>
            </GridItem>
          </GridContainer>

          <GridContainer>
            {MentorsData[2022].map((mentor, index) => (
              <GridItem xs={12} sm={4} md={4} key={index}>
                <Card testimonial plain>
                  <CardAvatar testimonial plain>
                    <img src={mentor.imageURL} alt={mentor.name} />
                  </CardAvatar>
                  <CardBody plain>
                    <h4 className={classes.title}>{mentor.name}</h4>
                    <Muted>
                      <h6>{mentor.mutedText}</h6>
                    </Muted>
                    <h5 className={classes.cardDescription}>
                      {htmlToReactParser.parse(mentor.description)}
                    </h5>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
      {/* Testimonials 3 END */}
      <Footer
        content={
          <div>
            <div className={classes.left}>
              {/* <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/home" className={classes.block}>
                    Home
                  </Link>
                </ListItem>{" "}
                <ListItem className={classes.inlineBlock}>
                  <Link to="/teams" className={classes.block}>
                    Teams
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link to="/blog-posts" className={classes.block}>
                    Blogs
                  </Link>
                </ListItem>
              </List> */}
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} Made by{' '}
              <a href="http://www.franked.io" target="_blank">
                franked.io
              </a>{' '}
              for a kinder web.
            </div>
          </div>
        }
      />
    </div>
  );
}
