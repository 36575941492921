import React from "react";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Footer from "components/Footer/Footer.js";
import { makeStyles } from "@material-ui/core";
import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle";

const useStyles = makeStyles(blogPostPageStyle);

export function BlogFooter() {
  const classes = useStyles();
  return (
    <Footer
      content={
        <div>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <Link to="/" className={classes.block}>
                  Home
                </Link>
              </ListItem>{" "}
              <ListItem className={classes.inlineBlock}>
                <Link to="/about-us" className={classes.block}>
                  About Us
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                  <Link to="/participant-information" className={classes.block}>
                    Participant Information
                  </Link>
                </ListItem>
            </List>
          </div>
          <div className={classes.right}>
            &copy; {1900 + new Date().getYear()} Made by{" "}
            <a href="https://franked.io" target="_blank">
              franked.io
            </a>{" "}
            for a kinder web.
          </div>
        </div>
      }
    />
  );
}
