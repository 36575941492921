import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
// import Card from 'components/Card/Card.js';
// import CardHeader from 'components/Card/CardHeader.js';
// import CardBody from 'components/Card/CardBody.js';
// @material-ui icons
import Grid from '@material-ui/icons/GridOn';
import PhoneLink from '@material-ui/icons/Phonelink';
import AccessTime from '@material-ui/icons/AccessTime';
// import AttachMoney from '@material-ui/icons/AttachMoney';
import Adb from '@material-ui/icons/AdbOutlined';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// images
import LogosNMTafe from 'assets/img/assets-for-demo/ourClients/LogosNMTafe.jpg';
// import LogosJBA from 'assets/img/assets-for-demo/ourClients/LogosJBA.jpg';
// import LogosCouncil from 'assets/img/assets-for-demo/ourClients/LogosCouncil.png';
import LogosCouncil2 from 'assets/img/assets-for-demo/ourClients/LogosCouncil2.png';
// import LogosCouncil3 from 'assets/img/assets-for-demo/ourClients/LogosCouncil3.jpg';
import LogosCurtin from 'assets/img/assets-for-demo/ourClients/LogosCurtin.jpeg';
import LogosUWA from 'assets/img/assets-for-demo/ourClients/LogosUWA.jpeg';
import LogosECU from 'assets/img/assets-for-demo/ourClients/LogosECU.png';
import LogosSpacecubed from 'assets/img/assets-for-demo/ourClients/LogosSpacecubed.png';
import LogosStudyPerth from 'assets/img/assets-for-demo/ourClients/LogosStudyPerth.jpeg';
import LogoCCAT from "assets/img/assets-for-demo/ourClients/LogoCCAT.png"
import LogoNTRO from "assets/img/assets-for-demo/ourClients/NTRO-Logo-RGB.webp"
// import LogosLotteryWest from 'assets/img/assets-for-demo/ourClients/LogosLotteryWest.jpeg';
// import LogosWAGovt from 'assets/img/assets-for-demo/ourClients/LogosWAGovt.png';
// import LogosCET from 'assets/img/LogosCET.jpeg';
import FRANKlogo from 'assets/img/assets-for-demo/ourClients/FRANKlogo.png';
// import LogosNIT from 'assets/img/assets-for-demo/ourClients/LogosNIT.png';
import LogoNITInlineRGBFullColour from 'assets/img/assets-for-demo/ourClients/NIT_Inline_RGB_FullColour.svg';
// import LogoNITInlineRGBFullColour from 'assets/img/assets-for-demo/ourClients/LogoNITInlineRGBFullColour.jpg';
import Logosaiwt from 'assets/img/assets-for-demo/ourClients/Logosaiwt.png';
// import LogoMU from 'assets/img/assets-for-demo/ourClients/LogoMU.png';
// import LogoWeSchool from 'assets/img/assets-for-demo/ourClients/LogoWeSchool.png';
// import LogoUnistudent from 'assets/img/assets-for-demo/ourClients/LogoUnistudent.png';
// import LogoPanditDeendayal from 'assets/img/assets-for-demo/ourClients/LogoPanditDeendayal.png';
// import LogoAmityUniversity from 'assets/img/assets-for-demo/ourClients/LogoAmityUniversity.jpg';
import LogoRediSoftware from 'assets/img/assets-for-demo/ourClients/LogoRediSoftware.png';
// import LogoMetronet from 'assets/img/assets-for-demo/ourClients/MetronetLogoCMYKS.svg';
import LogoCareerSuccessAustralia from 'assets/img/assets-for-demo/ourClients/LogoCareerSuccessAustralia.jpg';
import LogoGWA from 'assets/img/assets-for-demo/ourClients/LogoGWA.png';
// import people from 'assets/img/assets-for-demo/people.png';
// import people1 from 'assets/img/assets-for-demo/people1.png';
// import people2 from 'assets/img/assets-for-demo/people2.png';

import overviewStyle from 'assets/jss/material-kit-pro-react/views/presentationSections/overviewStyle.js';

const useStyles = makeStyles(overviewStyle);

export default function SectionOverview() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div
        className={classes.features5}
        style={{
          backgroundImage: `url(${require('assets/img/assets-for-demo/features-5.jpg')})`,
        }}
      >
        <GridItem md={8} className={classNames(classes.mlAuto, classes.mrAuto)}>
          <h2 className={classes.title}>Our 1.5 Week Supercharged Journey</h2>
        </GridItem>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title="Day 1: Orientation Day & Summit 1"
                description={
                  <p>
                    Teams from University of Western Australia, Edith Cowan
                    University, North Metro TAFE, Australian Institute of
                    Workplace Training and National Institute of Technology are
                    introduced to each other and their mentors. It is high
                    energy and engagement with both education and entertainment.
                    An inspiring keynote session is delivered by a wellknown WA
                    entreprenuer. Students are introduced to key
                    entrepreneurship and start up methodologies.
                  </p>
                  // <p>
                  //   Teams from Curtin University, University of Western
                  //   Australia, Edith Cowan University, North Metro TAFE and
                  //   others are introduced to each other and their mentors. It is
                  //   high energy and engagement with both education and
                  //   entertainment. An inspiring keynote session is delivered by
                  //   a wellknown WA entreprenuer. Students are introduced to key
                  //   entrepreneurship and start up methodologies.
                  // </p>
                }
                icon={Grid}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
            <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title="Day 2: Virtual Summit 2"
                description={
                  <p>
                    Virtual Summit #2 kicks off with another inspiring keynote
                    speaker. Students learn how to wireframe and test their
                    ideas. Teams finalise their business ideas and get ready for
                    their pitches.
                  </p>
                }
                icon={PhoneLink}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
            <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title="Day 3: Pitch Event"
                description={
                  <p>
                    The 5 finalist teams will be announced, and then go on to
                    pitch their business idea in front of 3 esteemed judges and
                    a captive audience. The judges will deliberate and announce
                    the 3 winning teams.
                  </p>
                }
                icon={Adb}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
          </GridContainer>

          <GridContainer justify="center">
            {/* <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title="Day 4: Pitch Night"
                description={
                  <p>
                    This is the Pitch Night infront of 3 esteemed judges and a
                    captive audience. Each shortlisted team will pitch to the
                    judges and 3 winning teams will be announced.
                  </p>
                }
                icon={AttachMoney}
                iconColor="gray"
                vertical={true}
              />
            </GridItem> */}
            <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title="Reward & Reflection"
                description={
                  <p>
                    It is participation in the JOONDALUP INNOVATION CHALLENGE
                    journey that is the real winning opportunity. Each student
                    that completes this journey reaps the rewards including
                    building great friendships and being part of a community.
                    They also get a chance to reflect on their self development
                    and build their employability skills. Businesses and
                    residents of Joondalup benefit from the ideas and creativity
                    of the students.
                  </p>
                }
                icon={AccessTime}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className={classes.sectionTestimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <h2 className={classes.title}>Our Partners</h2>
              <h5 className={classes.description}>
                The Joondalup Innovation Challenge is made possible with support
                from the following organisations from industry, local
                government, state government and community.
                {/* <b> 1500 students</b>{" "} */}
              </h5>
            </GridItem>
          </GridContainer>

          {/* <GridContainer>
            <GridItem md={4} sm={4}>
              <Card plain profile>
                <GridContainer>
                  <GridItem md={3} sm={3}>
                    <CardHeader image plain>
                      <img src={people} alt="..." />
                      <div
                        // className={classes.coloredShadow}
                        style={{
                          backgroundImage: "url(" + people + ")",
                          opacity: "1"
                        }}
                      />
                      <div
                        // className={classes.coloredShadow}
                        style={{
                          backgroundImage: "url(" + people + ")",
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem md={9} sm={9}>
                    <CardBody plain className={classes.alignLeft}>
                      <h4 className={classes.cardTitle}>
                        INTERCHANGE 2019 Participant
                      </h4>
                      <p className={classes.cardDescription}>
                        {'"'}This event is AMAZEBALLS!{"'"}
                      </p>
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem md={4} sm={4}>
              <Card plain profile>
                <GridContainer>
                  <GridItem md={3} sm={3}>
                    <CardHeader image plain>
                      <img src={people2} alt="..." />
                      <div
                        // className={classes.coloredShadow}
                        style={{
                          backgroundImage: "url(" + people2 + ")",
                          opacity: "1",
                        }}
                      />
                      <div
                        // className={classes.coloredShadow}
                        style={{
                          backgroundImage: "url(" + people2 + ")",
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>{' '}
            <GridItem md={4} sm={4}>
              <Card plain profile>
                <GridContainer>
                <GridItem md={3} sm={3}>
                    <CardHeader image plain>
                      <img src={people1} alt="..." />
                      <div
                        // className={classes.coloredShadow}
                        style={{
                          backgroundImage: "url(" + people1 + ")",
                          opacity: "1",
                        }}
                      />
                      <div
                        // className={classes.coloredShadow}
                        style={{
                          backgroundImage: "url(" + people1 + ")",
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                <GridItem md={9} sm={9}>
                <CardBody plain className={classes.alignLeft}>
                      <h4 className={classes.cardTitle}>
                        INTERCHANGE 2019 Participant
                      </h4>
                      <p className={classes.cardDescription}>
                        {'"'}It is a great platform to network and get to learn
                        about the work culture of Australia{'"'}
                      </p>
                    </CardBody>
                </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer> */}

          <div className={classes.ourClients}>
            <GridContainer
              justify="center"
              style={{ padding: '10px 0', alignItems: 'center' }}
            >
              <GridItem md={3} sm={3}>
                <img src={LogosUWA} alt="University of Western Australia" />
              </GridItem>
              {/* <GridItem md={3} sm={3}>
                <img src={LogosCurtin} alt="Curtin University" />
              </GridItem> */}
              <GridItem md={3} sm={3}>
                <img src={LogosECU} alt="Edith Curtin University" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogoNITInlineRGBFullColour} alt="NIT Australia" />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{ padding: '10px 0', alignItems: 'center' }}
            >
              <GridItem md={3} sm={3}>
                <img src={LogosNMTafe} alt="North Metro TAFE" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={Logosaiwt} alt="AIWT" />
              </GridItem>
              {/* <GridItem md={3} sm={3}>
                <img src={LogosSpacecubed} alt="Spacecubed" />
              </GridItem> */}
              <GridItem md={3} sm={3}>
                <img src={LogoCCAT} alt="CCAT" />
              </GridItem>

            </GridContainer>
            <GridContainer
              justify="center"
              style={{ padding: '10px 0', alignItems: 'center' }}
            >
              <GridItem md={3} sm={2}>
                <img src={LogoNTRO} alt="CCAT" />
              </GridItem>
               <GridItem md={3} sm={2}>
                <img src={LogoRediSoftware} alt="Redi Software" />
              </GridItem>
            </GridContainer>
            {/* <GridContainer
              justify="center"
              style={{ padding: '10px 0', alignItems: 'center' }}
            >
              <GridItem md={3} sm={3}>
                <img
                  src={LogoCareerSuccessAustralia}
                  alt="Career Success Australia"
                  style={{ maxWidth: '200px' }}
                />
              </GridItem>
            </GridContainer> */}
            {/* <GridContainer
              justify="center"
              style={{ padding: '10px 0', alignItems: 'center' }}
            >
              <GridItem md={3} sm={3}>
                <img src={LogoWeSchool} alt="WESchool" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img
                  src={LogoPanditDeendayal}
                  alt="Pandit Deendayal University"
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogoAmityUniversity} alt="Amity University" />
              </GridItem>
            </GridContainer> */}
            {/* <GridContainer style={{ padding: '10px 0' }}>
              <GridItem md={3} sm={3}>
                <img src={LogoRediSoftware} alt="Redi Software" />
              </GridItem>
            </GridContainer> */}
          </div>
          <div className={classes.sectionTestimonials}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem
                  md={8}
                  className={classNames(classes.mlAuto, classes.mrAuto)}
                >
                  <h4 className={classes.title}>Co-created by</h4>
                  <div className={classes.ourClients}>
                    <GridContainer justify="center">
                      <GridItem md={12} sm={12}>
                        <img
                          src={LogosCouncil2}
                          alt="franked.io"
                          style={{ maxWidth: '200px' }}
                        />
                      </GridItem>
                    </GridContainer>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
          {/* <div className={classes.ourClients}>
            <GridContainer
              justify="center"
              style={{ padding: '10px 0', alignItems: 'center' }}
            >
              <GridItem md={3} sm={3}>
                <img src={LogosUWA} alt="University of Western Australia" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogosCurtin} alt="Curtin University" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogosECU} alt="Edith Curtin University" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogosNIT} alt="NIT Australia" />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{ padding: '10px 0', alignItems: 'center' }}
            >
              <GridItem md={3} sm={3}>
                <img src={LogosJBA} alt="JBA" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogosNMTafe} alt="North Metro TAFE" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={Logosaiwt} alt="AIWT" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogosSpacecubed} alt="Spacecubed" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogoMU} alt="Murdoch University" />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{ padding: '10px 0', alignItems: 'center' }}
            >
              <GridItem md={3} sm={3}>
                <img src={LogoWeSchool} alt="WESchool" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img
                  src={LogoPanditDeendayal}
                  alt="Pandit Deendayal University"
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogoAmityUniversity} alt="Amity University" />
              </GridItem>
            </GridContainer>
            <GridContainer style={{ padding: '10px 0' }}>
              <GridItem md={3} sm={3}>
                <img src={LogoRediSoftware} alt="Redi Software" />
              </GridItem>
            </GridContainer>
          </div> */}
        </div>
      </div>
      <div className={classes.sectionTestimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <h5 className={classes.title}>
                This initiative is made possible through the support of the WA
                Government and StudyPerth
              </h5>
              <div className={classes.ourClients}>
                <GridContainer
                  justify="center"
                  style={{ alignItems: 'center', gap: '10px' }}
                >
                  {/* <GridItem md={3} sm={2}>
                    <img src={LogoMetronet} alt="Metronet" />
                  </GridItem> */}
                  <GridItem md={12} sm={12}>
                    <img
                      src={LogosStudyPerth}
                      alt="StudyPerth"
                      style={{ maxWidth: '280px' }}
                    />
                  </GridItem>
                  {/* <GridItem md={3} sm={2}>
                    <img src={LogoRediSoftware} alt="Redi Software" />
                  </GridItem> */}
                </GridContainer>

                <GridContainer
                  justify="center"
                  style={{
                    alignItems: 'center',
                    gap: '10px',
                    marginTop: '50px',
                  }}
                >
                  {/* <GridItem md={12} sm={12}>
                    <img
                      src={LogoGWA}
                      alt="Government of Western Australia"
                      style={{ maxWidth: '420px' }}
                    />
                  </GridItem> */}
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classes.sectionTestimonials}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem
                  md={8}
                  className={classNames(classes.mlAuto, classes.mrAuto)}
                >
                  <h3 className={classes.title}>Powered by</h3>
                  <div className={classes.ourClients}>
                    <GridContainer justify="center">
                      <GridItem md={12} sm={12}>
                        <img src={FRANKlogo} alt="franked.io" />
                      </GridItem>
                    </GridContainer>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
