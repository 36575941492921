import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Gesture from '@material-ui/icons/Gesture';
import Build from '@material-ui/icons/Build';
import SecurityIcon from '@material-ui/icons/Security';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

import servicesStyle from 'assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.js';

const useStyles = makeStyles(servicesStyle);

export default function SectionServices() {
  const classes = useStyles();
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>This is what we do.</h2>
          <h5 className={classes.description}>
            Find out a little more about the JOONDALUP INNOVATION CHALLENGE.
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={12}>
          <InfoArea
            title="1. Project Background"
            description={
              <span>
                <p>
                  In a globally competitive world, many international students
                  take an investment approach to studying abroad and the ability
                  to build employability skills whilst studying in Australia is
                  considered very valuable. The JOONDALUP INNOVATION CHALLENGE
                  provides a platform for international and local students to
                  develop these crucial employability skills and networks while
                  studying in Australia. JOONDALUP INNOVATION CHALLENGE is part
                  of the Work Integrated Learning journey and provides students
                  a chance to work on real-world problems in a fast paced and
                  engaging manner. JOONDALUP INNOVATION CHALLENGE also combats
                  the problem of social isolation as inter-varsity teams work
                  closely together making new networks and friends.{' '}
                </p>
                {/* <a href="#pablo">Find more...</a> */}
              </span>
            }
            icon={Gesture}
            iconColor="warning"
          />
        </GridItem>
        <GridItem md={4} sm={12}>
          <InfoArea
            title="2. Project Outcomes"
            description={
              <span>
                <p>
                  {' '}
                  JOONDALUP INNOVATION CHALLENGE enables students to build
                  employability skills such as teamwork, communication, problem
                  solving and creative thinking skills. This program encourages
                  students to network and build local Australian and
                  international networks by exposing them to the WA start-up and
                  entrepreneurship eco-system including incubators and
                  co-working spaces. The JOONDALUP INNOVATION CHALLENGE
                  additionally improves the employability skills of students and
                  gives them ‘real-world’ Australian experience.{' '}
                </p>
                {/* <a href="#pablo">Find more...</a> */}
              </span>
            }
            icon={Build}
            iconColor="warning"
          />
        </GridItem>
        <GridItem md={4} sm={12}>
          <InfoArea
            title="3. Project Deliverables"
            description={
              <span>
                <p>
                  The project deliverables include the involvement of 50
                  students as well as industry mentors. It is brought together
                  by the collaboration of 5 educational organisations and
                  managed by{' '}
                  <a href="http://franked.io" target="new window">
                    Franked.io{' '}
                  </a>{' '}
                  . Students prepare themselves for the future of work by
                  learning how to rapidly make decisions and deploy creative,
                  technology based-solutions. They can build their confidence
                  and capability in digital literacy and critical technology
                  skills by attending Virtual and In-person Summits and by
                  preparing video pitches using the latest technology platforms.{' '}
                </p>
                {/* <p>
                  The project deliverables include the involvement of 100
                  students as well as industry mentors. It is brought together
                  by the collaboration of 6 educational organisations and
                  managed by{' '}
                  <a href="http://franked.io" target="new window">
                    Franked.io{' '}
                  </a>{' '}
                  . Students prepare themselves for the future of work by
                  learning how to rapidly make decisions and deploy creative,
                  technology based-solutions. They can build their confidence
                  and capability in digital literacy and critical technology
                  skills by attending Virtual and In-person Summits and by
                  preparing video pitches using the latest technology platforms.{' '}
                </p> */}
                {/* <a href="#pablo">Find more...</a> */}
              </span>
            }
            icon="mode_edit"
            iconColor="warning"
          />
        </GridItem>
      </GridContainer>
      {/* <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Innovation Challenge Themes</h2>
          <h5 className={classes.description}>
            This innovation challenge was centred around solving for the
            economic priorities and upcoming challenges faced by City of
            Joondalup Council.
          </h5>
        </GridItem>
      </GridContainer> */}
      {/* <GridContainer>
        <GridItem md={3} sm={12}>
          <InfoArea
            title="1. Building Successful Businesses in Joondalup in the COVID- Safe Economy"
            description={
              <span>
                <p>
                  While life in Western Australia has been slowly returning to
                  normal, in many ways COVID-19 will continue to affect the
                  region and its economy for weeks, months, and perhaps years to
                  come. Being an interdependent economy, reliant on trade with
                  regional, national and international markets the impact of
                  restrictions on such things will continue to impact Joondaup
                  businesses and their supply chain. As a result, many
                  businesses have changed how they operate and deliver services
                  to reach alternative markets and diversify their offering. In
                  your responses to the questions below, we encourage you to
                  explore trends such as growth and pitfalls in online shopping
                  and (where appropriate) online ordering services such as
                  UberEats, Airbnb, Menulog etc.{' '}
                </p>
                <a href="#pablo">Find more...</a>
              </span>
            }
            icon={Gesture}
            iconColor="warning"
          />
        </GridItem>
        <GridItem md={3} sm={12}>
          <InfoArea
            title="2. Shaping the Future"
            description={
              <span>
                <p>
                  {' '}
                  The technology-driven world in which we live is a world filled
                  with innovation but also challenges and change. Driverless
                  cars, machines that read medical results, and algorithms that
                  respond to customers are all examples of exciting new forms of
                  automation. while these technologies increase productivity and
                  improve our lives, the McKinsey Global Institute forecasts
                  that by 2030, millions of today’s jobs will be lost to
                  automation. This transformation will ultimately see millions
                  of new jobs being created for graduates, many relating to
                  innovation and digital technologies.{' '}
                </p>
                <a href="#pablo">Find more...</a>
              </span>
            }
            icon={Build}
            iconColor="warning"
          />
        </GridItem>
        <GridItem md={3} sm={12}>
          <InfoArea
            title="3. Destination Joondalup"
            description={
              <span>
                <p>
                  Tourism is an important economic driver for Western Australia
                  and will play an important role in the State’s recovery as we
                  emerge from the global pandemic. Joondalup’s pristine beaches
                  and family-friendly attractions are drawcards for day trippers
                  looking for fun in the sun, while the diverse community
                  invites friends and relatives from near and far to spend time
                  exploring its beaches, trails, lakes, parks and dining and
                  entertainment precincts. World class education and health
                  precincts attract experts, innovators, students and
                  entrepreneurs, while the city also provides comprehensive
                  retail and professional services for many individuals and
                  families in Perth’s northern corridor. Global disruptions to
                  travel resulting from the COVID-19 pandemic have exposed how
                  much national and local economies rely on visitors. Joondalup
                  is no exception. In fact, compared to Western Australia as a
                  whole, the city is more reliant on visitor related industries
                  as a proportion of the local economy.{' '}
                </p>
                <a href="#pablo">Find more...</a>
              </span>
            }
            icon="mode_edit"
            iconColor="warning"
          />
        </GridItem>
        <GridItem md={3} sm={12}>
          <InfoArea
            title="4. Futureproofing Joondalup"
            description={
              <span>
                <p>
                  Strong cyber security is a fundamental element of our growth
                  and prosperity in a global economy. It is also vital for our
                  national security. It requires partnership involving
                  governments, the private sector and the community. Joonadalup,
                  known as the cyber capital of Australia, has a core focus
                  relating to cybersecurity, and this is to encourage and
                  attract students into the region to study in this rapidly
                  evolving field and to be able to support these students with
                  programs that enhance their employability skills and connect
                  them to future employment. With global changes to supply chain
                  and access to manufacturing opportunities, Australian
                  consumers and businesses are looking for advanced
                  manufacturing options closer to home. There is an opportunity
                  for Joondalup and WA to capitalise on the adoption of robotic
                  technology, automation and Artificial Intelligence to develop
                  this sector and create more jobs.
                </p>
                <a href="#pablo">Find more...</a>
              </span>
            }
            icon={SecurityIcon}
            iconColor="warning"
          />
        </GridItem>
      </GridContainer> */}
    </div>
  );
}
