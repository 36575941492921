import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card/Card';
import CardAvatar from 'components/Card/CardAvatar';
import CardBody from 'components/Card/CardBody';
import Muted from 'components/Typography/Muted';
import CardFooter from 'components/Card/CardFooter';
import { makeStyles, Button } from '@material-ui/core';
import teamsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';
import './TeamCard.scss';

const useStyles = makeStyles(teamsStyle);
export function TeamCard(props) {
  const classes = useStyles();
  const { id, name, motto, image, url } = props;
  return (
    <Card profile className="team-card">
      <CardAvatar className="card-avatar" profile>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img src={image} alt={name} />
        </a>
      </CardAvatar>
      <CardBody className="card-body">
        <h4 className={classes.cardTitle}>Team {id}</h4>
        <Muted>
          <h6 className={classes.cardCategory}>{name}</h6>
        </Muted>
        <p className={classes.description}>{motto}</p>
      </CardBody>
      <CardFooter profile className={classes.justifyContent}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Button className="m8" variant="contained" color="primary">
            Video Pitch
          </Button>
        </a>
      </CardFooter>
    </Card>
  );
}

TeamCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  motto: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
