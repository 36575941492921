import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
// @material-ui icons
import Grid from '@material-ui/icons/GridOn';
import PhoneLink from '@material-ui/icons/Phonelink';
import AccessTime from '@material-ui/icons/AccessTime';
import AttachMoney from '@material-ui/icons/AttachMoney';
import CardGiftcard from '@material-ui/icons/CardGiftcard';
import Adb from '@material-ui/icons/AdbOutlined';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// images
import LogosNMTafe from 'assets/img/assets-for-demo/ourClients/LogosNMTafe.jpg';
import LogosJBA from 'assets/img/assets-for-demo/ourClients/LogosJBA.jpg';
import LogosCouncil from 'assets/img/assets-for-demo/ourClients/LogosCouncil.png';
import LogosCurtin from 'assets/img/assets-for-demo/ourClients/LogosCurtin.jpeg';
import LogosUWA from 'assets/img/assets-for-demo/ourClients/LogosUWA.jpeg';
import LogosECU from 'assets/img/assets-for-demo/ourClients/LogosECU.png';
import LogosSpacecubed from 'assets/img/assets-for-demo/ourClients/LogosSpacecubed.png';
import LogosStudyPerth from 'assets/img/assets-for-demo/ourClients/LogosStudyPerth.jpeg';
import FRANKlogo from 'assets/img/assets-for-demo/ourClients/FRANKlogo.png';
import LogosNIT from 'assets/img/assets-for-demo/ourClients/LogosNIT.png';
import LogoNITInlineFullColor from 'assets/img/assets-for-demo/ourClients/NIT_Inline_RGB_FullColour.svg'
import LogoCCAT from "assets/img/assets-for-demo/ourClients/LogoCCAT.png"
import LogoNTRO from "assets/img/assets-for-demo/ourClients/NTRO-Logo-RGB.webp"
import LogoRediSoftware from "assets/img/assets-for-demo/ourClients/LogoRediSoftware.png"
// import LogoNITInlineFullColor from 'assets/img/assets-for-demo/ourClients/LogoNIT_Inline_RGB_FullColour.png'
import Logosaiwt from 'assets/img/assets-for-demo/ourClients/Logosaiwt.png';
import LogosSMTAFE from 'assets/img/assets-for-demo/ourClients/LogosSMTAFE.jpg';
import people from 'assets/img/assets-for-demo/people.png';
import people1 from 'assets/img/assets-for-demo/people1.png';
import people2 from 'assets/img/assets-for-demo/people2.png';

import overviewStyle from 'assets/jss/material-kit-pro-react/views/presentationSections/overviewStyle.js';

const useStyles = makeStyles(overviewStyle);

export default function SectionOverview() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.sectionTestimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <h2 className={classes.title}>Our Partners</h2>
              <h5 className={classes.description}>
                With support from the following organisations from industry,
                local government, state government and community.
              </h5>
            </GridItem>
          </GridContainer>
          <div className={classes.sectionTestimonials}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem
                  md={8}
                  className={classNames(classes.mlAuto, classes.mrAuto)}
                >
                  <h3 className={classes.title}>Co-created by</h3>
                  <div className={classes.ourClients}>
                    <GridContainer justify="center">
                      <GridItem md={12} sm={12}>
                        <img src={LogosCouncil} alt="franked.io" />
                      </GridItem>
                    </GridContainer>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
          <div className={classes.ourClients}>
            <GridContainer justify="center">
              <GridItem md={3} sm={3}>
                <img src={LogosUWA} alt="University of Western Australia" />
              </GridItem>
              {/* <GridItem md={3} sm={3}>
                <img src={LogosCurtin} alt="Curtin University" />
              </GridItem> */}
              <GridItem md={3} sm={3}>
                <img src={LogosECU} alt="Edith Curtin University" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogoNITInlineFullColor} alt="NIT Australia" />
              </GridItem>
              {/* <GridItem md={3} sm={3}>
                <img src={LogosNIT} alt="NIT Australia" />
              </GridItem> */}
            </GridContainer>
            <GridContainer justify="center">
              {/* <GridItem md={3} sm={3}>
                <img src={LogosJBA} alt="JBA" />
              </GridItem> */}
              <GridItem md={3} sm={3}>
                <img src={LogosNMTafe} alt="North Metro TAFE" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={Logosaiwt} alt="AIWT" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogoCCAT} alt="CCAT" />
              </GridItem>
              {/* <GridItem md={3} sm={3}>
                <img src={LogosSMTAFE} alt="South Metro TAFE" />
              </GridItem> */}
            </GridContainer>
            <GridContainer
              justify="center"
              style={{ padding: '10px 0', alignItems: 'center' }}
            >
              <GridItem md={3} sm={2}>
                <img src={LogoNTRO} alt="CCAT" />
              </GridItem>
               <GridItem md={3} sm={2}>
                <img src={LogoRediSoftware} alt="Redi Software" />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div className={classes.sectionTestimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <div className={classes.ourClients}>
                <GridContainer justify="center">
                  <GridItem md={3} sm={3}>
                    <img src={LogosStudyPerth} alt="Study Perth" />
                  </GridItem>
                  {/* <GridItem md={3} sm={3}>
                    <img src={LogosSpacecubed} alt="Spacecubed" />
                  </GridItem> */}
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className={classes.sectionTestimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <h3 className={classes.title}>Powered by</h3>
              <div className={classes.ourClients}>
                <GridContainer justify="center">
                  <GridItem md={12} sm={12}>
                    <img src={FRANKlogo} alt="franked.io" />
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
