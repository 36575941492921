export const blogpostsdata = {
  "1": {
    title: "Stepping outside my comfort zone",
    url: "/blog-post1",
    subheading: "Interdisciplinary Learning",
    paragraphs: [
      "I took part in the 2020 Joondalup Innovation Challenge as a representative for ECU. As a nursing student, entrepreneurial skills are far from my field of knowledge. I entered the challenge unsure of what to expect and apprehensive about how I would be able to contribute to the challenge for my team.",
      "My team were from a varying array of disciplines including maths, engineering, biomedical and social work and this seemed to help us merge as we all had a variety of personal and professional strengths.I was amazed at how detailed the training was and the support we received as a team from our mentor was second to none. ",
      "<b>Major Takeaways:</b> Through the challenge I was able to further develop a variety of skills including leadership, teamwork, time management and critical thinking. ",
      "For anyone considering applying, I would say do it! My team are now my friends, the education I received is now shaping my future and the experience is exhilarating, making you step outside your comfort zone while preparing you for the world of tomorrow."
    ],
    quote:
      "As a final year nursing student, while these skills were gained in a non-clinical setting, the knowledge I learnt from the JIC will be paramount to my future career.",
    author: "Alexandra Humphreys, Edith Cowan University",
    brand: "Alexandra Humphreys, Edith Cowan University",
    authorImage: require("assets/img/blogs/1/alex.jpg"),
    backgroundImgUrl: require("assets/img/blogs/1/background.jpg")
  },
  "2": {
    title: "Joondalup Innovation Challenge Fever: I’m busy NOW!",
    url: "/blog-post2",
    subheading: "The transition to Australia",
    paragraphs: [
      "Arriving in Perth on February 2020 and at the dawn of COVID 19, I quickly found myself  struggling to find a job, and to make ends meet. This situation not only affected me financially  but also mentally as I had considerable stress due to fear and uncertainty. ",
      "To survive the  transition, I had to make myself employable or at least, have the ability to transform my hobby  into an income source. This is why I joined the 2020 Joondalup Innovation Challenge and it did  not disappoint. ",
      "<strong>Major Takeaways: </strong> The event featured a series of activities ranging from the psychological aspect of  wellness to the business side of entrepreneurship and what it takes for a startup to succeed.  What stood out most was the group innovation activity where different students, from  different institutions and backgrounds worked together to innovate and pitch out the innovation enabling them to gain and use employability skills like teamwork, communication, problem  solving and creativity in a fast paced environment. ",
      "Furthermore, using the business canvas model that was taught to  us in the event, I am slowly but surely starting to work on turning my hobby for oil and gas insights to something that can generate income. "
    ],
    quote:
      "Following the event, I feel rejuvenated. I have since then found a job by employing the  employability skills I learnt during JIC and my pathway to landing an engineering summer  internship is more promising.",
    author: "George Yabesh, Edith Cowan University",
    brand: "George Yabesh, Edith Cowan University",
    authorImage: require("assets/img/blogs/2/george.jpg"),
    backgroundImgUrl: require("assets/img/blogs/2/background.jpg")
  },
  "3": {
    title: "Western Australia’s first virtual entrepreneurial challenge",
    url: "/blog-post3",
    subheading: "Preparing graduates for their technology future",
    paragraphs: [
      "Participating in JIC as WA’s first virtual entrepreneurial challenge is invaluable, bringing a memorable, enjoyable learning experience. In order to solve the problems Joondalup faced due to COVID-19, FRANK taught us entrepreneurial skills, introducing us to several professionals and business institutions.",
      "Applying our learning from them, FRANK challenged us to work with students from different universities via online.This surprised me because it brought different kinds of team work using technology, giving us exposure to communicate in this online environment.",
      "Moreover, those students from different educational backgrounds, even studying postgraduate courses while working internationally develops my social networking skill.",
      "<strong>Major Takeaway: </strong> Although I am not in a similar field of business, I found it relevant for my medicine career which is about solving problems together with teams and researching about communities. ",
      "Clearly, FRANK prepares us for a globalised world that heavily relies on technology, honing on our technical, communication, teamwork and problem solving skills which are essential for graduates, bringing confidence in their ability to even start up a business or work locally and internationally."
    ],
    quote:
      "Meeting such open-minded and enthusiastic students allows me to explore each other’s perspectives of entrepreneurship, realising how entrepreneurship can play an important role in several careers.",
    author: "Khin Sek, University of Western Australia",
    brand: "Khin Sek, University of Western Australia",
    authorImage: require("assets/img/blogs/3/khin.jpg"),
    backgroundImgUrl: require("assets/img/blogs/3/background.jpg")
  },
  "4": {
    title: "Understanding entrepreneurship as an engineer",
    url: "/blog-post4",
    subheading: "The entrepreneurial mindset",
    paragraphs: [
      "Coming from an engineering background, I did not have much exposure to entrepreneurship which is why I signed up to the Joondalup Innovation Challenge (JIC). I was curious to understand this foreign world of entrepreneurship and connect with like minded people along the way. I am sharing some of my key takeaways from this challenge. ",
      "<strong>Major Takeaway: </strong> I initially thought that entrepreneurship is about creating a business with the goal of generating income, but this experience changed my understanding of entrepreneurship.",
      "Entrepreneurship is about helping people solve their problems; entrepreneurs are curious people who are passionate about solving problems. This is not only a change in definition but a change in mindset, the entrepreneurial mindset is based around being able to adapt to problems and find creative solutions to solve them. This new mindset will be applied to my professional career and personal life, this will enable me to adapt to any problem.",
      "Working in multidisciplinary teams I have been able to develop my leadership, communication and team working skills. All these skills are very important for my professional career in engineering."
    ],
    quote:
      "JIC has provided me the opportunity to work in multidisciplinary teams, this opportunity is generally not provided at university.",
    author: "Rishabh Sharma, Curtin University",
    brand: "Rishabh Sharma, Curtin University",
    authorImage: require("assets/img/blogs/4/rishabh.jpg"),
    backgroundImgUrl: require("assets/img/blogs/4/background.jpg")
  },
  "5": {
    title: "Joondalup Innovation Challenge Reflection",
    url: "/blog-post5",
    subheading: "Anyone can be an entrepreneur",
    paragraphs: [
      "Joondalup Innovation Challenge (JIC) was an experience like no other. JIC created in me a great desire to become innovative and to develop a culture of problem-solving. This program provided a platform for students to learn about entrepreneurship, business agility, business modelling, product prototyping and testing. It sharpened my skills in communication, leadership, and networking.",
      "These skills might be taught in school, but they can only be developed through practice. JIC gave us the opportunity to practice and grow these skills. It is surprising how much one can learn in only one week, and as Natasha Munasinghe CEO of FRANK mentioned, JIC provided students with a mini degree in business.",
      "<strong>Major Takeaway: </strong> Entrepreneurship is about identifying a problem in the society and developing a solution that people are willing to pay you for.",
      "Embracing technology is crucial as it will provide us with many opportunities. We can all be innovative, we just need to believe in our ideas. Entrepreneurs can experience burnout and persistent stress. It is important to stop and take a few deep breaths as you go on with your day. It would be helpful to practice meditation to manage stress."
    ],
    quote: "Being an entrepreneur is like anything else and nothing else.",
    author: "Glenn Murray of Sapien Cyber.",
    brand: "Wangui Theuri, Edith Cowan University ",
    authorImage: require("assets/img/blogs/5/wangui.jpg"),
    backgroundImgUrl: require("assets/img/blogs/5/background.jpg")
  }
};
