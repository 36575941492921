import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Button from 'components/CustomButtons/Button.js';
import Muted from 'components/Typography/Muted.js';

import teamsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';

import KateKirwin from 'assets/img/faces/KateKirwin.jpg';
import SpeakerLouise from 'assets/img/faces/SpeakerLouise.png';
import SpeakerDaryl from 'assets/img/faces/SpeakerDaryl.jpeg';
import Speakerglenn from 'assets/img/faces/Speakerglenn.jpeg';
import Speakerlucas from 'assets/img/faces/Speakerlucas.jpeg';
import Speakerchibs from 'assets/img/faces/Speakerchibs.jpeg';
import SpeakerTom from 'assets/img/faces/SpeakerTom.jpeg';
import MentorKim from 'assets/img/faces/MentorKim.jpeg';
import ComingSoon from 'assets/img/faces/ComingSoon.png';

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              {/* <h2 className={classes.title}>Our Speakers</h2> */}
              {/* <h5 className={classes.description}>
                Meet the amazing WA and Australian speakers, entrepreneurs and
                leaders that will be joining us for the Joondalup Innovation
                Challenge
              </h5> */}
            </GridItem>
          </GridContainer>
          <GridContainer>
            {/* <GridItem xs={12} sm={2} md={2}></GridItem> */}
            <GridItem xs={12} sm={8} md={8}>
              {/* <Card profile plain>
                <CardAvatar profile plain>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={KateKirwin} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Kate Kirwin</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>
                      Founder of She Codes
                    </h6>
                  </Muted>
                  <p className={classes.description}>
                    Kate is a passionate advocate for women in STEM and the
                    innovation ecosystem. Founder of She Codes, she has led the
                    steep growth of the project since 2015. Thanks to her work,
                    more than 6500 women have learnt to code. She has hustled to
                    source more than a million dollars from government and
                    industry to support women in STEM. With a regional
                    upbringing, she strongly believes in empowering women from
                    the world’s remotest regions, from as far north as Port
                    Hedland and Karratha, and east as far as Brisbane. Kate has
                    also contributed to the growth of Spacecubed, and since 2014
                    has played a pivotal role in building community and
                    coordinating other programs including Plus Eight, Startup
                    Weekend, Meshpoints. Kate was recently awarded a Business
                    News 40 under 40 award, the West Australian Rising Star
                    award and was a runner up for the 2019 and 2020 Seven News
                    Young Achiever Awards.
                  </p>
                </CardBody>
              </Card> */}
            </GridItem>
            <GridItem xs={12} sm={2} md={2}></GridItem>
            {/* <GridItem xs={12} sm={6} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="" onClick={(e) => e.preventDefault()}>
                    <img src={Speakerchibs} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Chibs Okereke Murray</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>Meditation & Resilience Specialist</h6>
                  </Muted>
                  <p className={classes.description}>
                    Chibs has gone from being a CEO of a very successful
                    healthcare company to now being a Mindfulness Coach. He will
                    support and enable students to perform at their best by
                    kicking off each session with a dose of mindfulness and
                    balance.
                  </p>
                </CardBody>
              </Card>
            </GridItem>  */}
            {/* <GridItem xs={12} sm={6} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img src={MentorKim} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Kim Smith</h4>
                  <Muted>
                  <h6 className={classes.cardCategory}>FOUNDING DIRECTOR & CEO @ STANDING STRONG INTERNATIONAL</h6>
                  </Muted>
                  <p className={classes.description}>
                  {''}Kim Smith is the founder of Standing Strong Clubs, an international health and wellbeing organisation providing preventative physical and mental health and wellbeing programs for children and teens.{''}                    
                  {''}In 2020, Kim was awarded the Telstra Business Womens’ Award for Purpose & Social Enterprise WA and was the 2021 winner of the Business News 40Under40 Award for Community & Social Enterprise. Kim and Standing Strong Clubs have been endorsed by Oprah Winfrey and have been featured on The Today Show, Daily Telegraph, ABC Radio, The Western Times, Girlfriend Magazine, Dolly Magazine and many more media outlets.{''}
                  </p>
                </CardBody>
              </Card>
            </GridItem>  */}
            {/* <GridItem xs={12} sm={6} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="" onClick={(e) => e.preventDefault()}>
                    <img src={SpeakerTom} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Tom Young</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>CEO @ udrew</h6>
                  </Muted>
                  <p className={classes.description}>
                    Tom has worked in the building industry in Australia and Asia, specializing in: Geo-engineering investigations, Soil mechanics, Geo-technical Laboratory's & analysis, Structural Drafting & design, Modular Fabrication, Architecture, System designer, Software development and expert kebab advisor / mentor.
                  </p>
                </CardBody>
              </Card>
            </GridItem>  */}
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              {/* <h5 className={classes.description}>
                Announcing soon
              </h5> */}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
