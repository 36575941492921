import React from "react";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core";
import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle";
import PropTypes from "prop-types";

const useStyles = makeStyles(blogPostPageStyle);
export function BlogHeader(props) {
  const classes = useStyles();

  return (
    <Parallax image={props.backgroundImgUrl} filter="dark">
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={8} className={classes.textCenter}>
            <h1 className={classes.title}>{props.title}</h1>
            <h4 className={classes.subtitle}>{props.subtitle}</h4>
            <br />
          </GridItem>
        </GridContainer>
      </div>
    </Parallax>
  );
}

BlogHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  backgroundImgUrl: PropTypes.any.isRequired
};
