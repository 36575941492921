export const MentorsData = {
  2022: [
    {
      id: 1,
      name: 'Jiaranai Keatnuxsuo',
      imageURL: require('assets/img/faces/MentorJiaranai.jpg'),
      mutedText: 'TECHNICAL ACCOUNT MANAGER @ MICROSOFT',
      description:
        'Jia is a thriving Tech Optimiser, a STEM Advocate, a TEDx Speaker, a multi-awards winner of Women in Technology Western Australia 2019, NASA Space App Challenge, Energy Hack, Transport Hack WA and UWA Innovation Challenge. Jia works at Microsoft. <br/><br/> She is a Winner of Women in Technology Western Australia 2019. You often find her involved in community events that increase technology accessibility and inclusiveness to the underrepresented groups such as encouraging other women to come into tech and inspiring disadvantaged students to be curious and confident in the world of STEM.',
    },
    {
      id: 2,
      name: 'Yvonne Power',
      imageURL: require('assets/img/faces/MentorYvonne.jpg'),
      mutedText: 'COFOUNDER & COO @ VILLAGE ENERGY',
      description:
        'Yvonne is passionate about building a sustainable energy future, especially in communities and developing nations. Yvonne is co-founder and Chief Operations Officer of Village Energy, a WA born start-up that has developed energy technology to operate at the edge of the energy grid and deliver cleaner, cheaper and more reliable power to underserved populations.Yvonne led the development of a highly successful pilot project in Andhra Pradesh, India, with which WA has a sister-state relationship.Since the pilot, Village Energy has signed agreements across India, Indonesia, Vietnam and Cambodia and has a potential pipeline to millions of customer connections. <br/><br/> Village Energy is backed by a Bill Gates chaired venture capital fund, Breakthrough Energy Ventures, and has been recognized as an APAC25 company and is the 2020 winner of the Australian Technologies Competition in energy.Yvonne is a mother of three, 2020 40 under 40 award winner, and formerly worked at Horizon Power as Manager Strategy, helping to drive the shift to renewable and distributed energy microgrids.',
    },
    // {
    //   id: 3,
    //   name: 'Jacqui Nelson',
    //   imageURL: require('assets/img/faces/MentorJacqui.jpeg'),
    //   mutedText: 'CEO @ DEKKO SECURE',
    //   description:
    //     'Jacqui is the CEO of Dekko Secure, an Australian cyber security company that helps organisations to share, collaborate and communicate with total privacy and security. She has a passion for innovation and success and making a positive contribution to business and society. Jacqui has been closely involved in the start up and successful growth of several commercial and social enterprise ventures.',
    // },
    {
      id: 3,
      name: 'Tuguy Esgin',
      imageURL: require('assets/img/faces/MentorTuguy.jpg'),
      mutedText: 'Director, Indigenous Research @ Clariti Indigenous',
      description:
        'I am a Noongar/Yamatji man with over 20 years experience in working constructively with community development, engaging with stakeholders focusing on emerging and persistent social issues in managing programs and projects. I have graduate with an MBA and used the qualification to undertake business planning which has and can continue to strongly align to community, council and corporate vision. I have a footprint in both worlds which allows me to operate as a change agent. I have a demonstrated history action and via scientific publications of successful in delivering training, projects and programs in urban, regional and remote communities. As a mentor I influence others by modelling high performance aligned with cultural values, ethics and vision. I am informed about emerging community trends and broker on identify and leveraging long-term opportunities. <br/><br/> I have been fortunate enough to travel the world and work in various countries. My greatest attribute is my ability to network and build rapport with people from different cultural, linguistical and socioeconomic backgrounds.',
    },
    {
      id: 4,
      name: 'Dr Eunice Sari',
      imageURL: require('assets/img/faces/MentorEunice.jpeg'),
      mutedText: 'CEO & CO-FOUNDER @ UX INDONESIA',
      description:
        'Dr. Eunice Sari is the CEO and Co-Founder of UX Indonesia, the first insight-driven UX Research, Training, and Consulting Company based in Indonesia since 2002, and co-founder of Customer Experience Insight Pty Ltd (Australia). Having more than 15 years of experience in academics and industries, she has helped many. She is also the first Asian female Google Global elite mentor in Product Design and Strategy (UX, UI) helping startups around the world,  articularly from emerging nations in Southeast Asia, Africa, Eastern Europe and Latin America.',
    },
    {
      id: 5,
      name: 'George Mavros',
      imageURL: require('assets/img/faces/MentorGeorge.jpg'),
      mutedText: 'BUSINESS COACH @ ETSI CONSULTING',
      description:
        'Specialties: Sales Marketing and Business consulting, Patent and Intellectual Property, negotiating and mediation, personal development and soft skills training, motivational speaking and corporate training, product development. I have been in sales marketing and distribution for over 30 years, I have represented companies like Artline Marking Pens, WD40, Armor All, Alcan Aluminium, Barilla Pastas and Spaghetti. and sold to Majors in most areas of the Market place, be it Grocery – Confectionery – Hardware - Pharmaceutical or Government. I created my first product at the age of 11 at the same time started my first business venture. Along the way I have learnt many secrets of the best, in Sales Marketing and Patenting and now look to use that knowledge and experience to assist others to realise their true potential. Be it in a commercial or person perspective.',
    },
    {
      id: 6,
      name: 'Erin Clark',
      imageURL: require('assets/img/faces/MentorErin.jpg'),
      mutedText:
        'Technology Commercialisation Specialist @ The Exchange Collective',
      description:
        'I’m a strategic facilitator who walks side-by-side with my clients to ensure a customer-centric approach. I’ve solved big (and small) growth challenges; developed technology-driven solutions and embedded new ways of working for almost 20 years across the Asia Pacific region. I’m all about balance for organisations and the people that work in them. I help organisations move forward with their technology by:<br/> <br/> - Providing a strategic extension to teams during key milestones and critical parts of their journey<br/> <br/> - Guide organisations to establish systems and processes to grow sustainably while meeting client needs<br/> <br/>  - Getting investment ready with clear and achievable pitches and plans<br/> <br/> - Helping founders to find clarity and get traction, achieving product, market and customer fit',
    },
    {
      id: 7,
      name: 'Jon Cuthbert',
      imageURL: require('assets/img/faces/MentorJon.jpg'),
      mutedText: 'COO @ Grafa & Multiplier Media',
      description:
        'A co-founder of finance media platform Grafa and investor relations firm Multiplier Media, with over 20 years’ experience across branding, marketing and corporate communications. Specialising in the development of announcements for ASX-listed companies, investor presentations and corporate websites, I’ve got acute attention to detail, which is invaluable during high stakes projects that require strategic thinking and finesse. Working across the Grafa ecosystem, as well as advising a range of ASX-listed companies, tech startups and crypto companies, I’ve been heavily involved in the growth of dozens of companies from the startup phase to achieving multi-hundred million dollar valuations.',
    },
    {
      id: 8,
      name: 'Josh Tedjasaputra',
      imageURL: require('assets/img/faces/MentorJosh.jpg'),
      mutedText: 'Director @UX Indonesia',
      description:
        'As a Google-certified Design Sprint Master, Josh (Adi Tedjasaputra, MSc) builds and nurtures business and innovation at UX Indonesia and Customer Experience Insight Pty Ltd. He has a passion for the design, development, and use of Information and Communication Technology for solving business problems and leveraging the User Experience of products and services. For more than 20 years, he has been helping international companies in creating business strategy, disruptive technology, marketing, and branding of successful products and services. With his engineering and computer science background, combined with his native and deep insights on Indonesian business ecosystem, he has introduced strategic and sustainable solutions that optimize the bottom line of businesses in different vertical industries, while at the same enhancing the life quality of millions of users and customers in Indonesia and around the world. His expertise includes Intranet and Internet Portal technology, Financial Technology, Usability Engineering, Agile UX, Design Thinking, Computational Thinking, Design Sprint, the Internet of Things, Machine Learning, Big Data, and Artificial Intelligence.',
    },
    {
      id: 9,
      name: 'Brendon Ellis',
      imageURL: require('assets/img/faces/MentorBrendan.jpg'),
      mutedText: 'Principal Consultant @ ESBS',
      description:
        'As the principal consultant & coach at ESBS, I will personally assist you to regain the joy and satisfaction of owning a successful business. I have decades of business experience and have recently completed a Masters degree in business motivation. I am a certified Chief Happiness Officer and a DISC Advanced Accredited Consultant. I have passionately pursued understanding and living a healthy balanced life which I now energetically want to share. I am an experienced business person with a broad eclectic mix of skills. Well-traveled and widely educated.',
    },
    {
      id: 10,
      name: 'Amanda Walker',
      imageURL: require('assets/img/faces/MentorAmanda.jpeg'),
      mutedText: 'CEO and Founder of Aussie Holiday Stays @ AUSSIE HOME STAYS',
      description:
        'Amanda Walker is the founder of WA HomeStay, an online booking platform for WA holiday accommodation. She is passionate about promoting sustainable tourism in WA and value based business practices. As a female founder of a tech startup, Amanda has overcome many of the entrepreneurial challenges startups face and is currently moving WA HomeStay to scale.<br/> <br/> Amanda was named as a 2020 Business News 40 Under 40 Winner, was a finalist in the WA Tourism Awards 2019 "New Business" Category and a finalist in the INCITE "Start Up of the Year" Awards 2019. She is also an Alumni of WA Leaders.',
    },
    {
      id: 11,
      name: 'Greg Rogers',
      imageURL: require('assets/img/faces/MentorGreg.jpg'),
      mutedText: 'CEO & FOUNDER @ REthink HQ',
      description:
        'Creator of the Rethink Infinity Sales Pipeline and the 5Ps framework, Greg is a Business Performance Coach who helps businesses to increase sales, improve their customer experience and lift productivity.',
    },
    {
      id: 12,
      name: 'Liora Meyer',
      imageURL: require('assets/img/faces/Woman.png'),
      mutedText: 'Teacher @ regional Australia',
      description:
        'I graduated from Curtin University with a Bachelor of Commerce before going on to complete my CA at BDO. After working in corporate tax for 5 years I started working in the car industry as a finance manager. This position was fast paced and KPI driven. After 9 years in the position, I was after a change of pace and enrolled in my Masters of Teaching. Whilst studying full time, I started contracting to car dealerships, helping them improve process and improve results. I am now a high school teacher in regional WA and I am passionate about promoting and teaching Financial literacy skills from a young age. I was also the mentor of the winning team last year!',
    },
    {
      id: 13,
      name: 'Jin S. Yoon',
      imageURL: require('assets/img/faces/MentorJinSYoon.jpeg'),
      mutedText:
        'Entrepreneur/Coach & Consultant @ Eclectic Global Consultancy PTY LTD',
      description:
        'Proven track record of consulting start-ups to create and identify goals and visions. Provided and implemented efficient digital marketing strategies based on researches and market trends. Extensive experience with cross-cultural coaching based on contextual self-awareness, consciousness and mindfulness leadership trainings.',
    },
    {
      id: 14,
      name: 'Heather Preston',
      imageURL: require('assets/img/faces/MentorHeather.jpeg'),
      mutedText: 'STRATEGIC RELATIONSHIPS MANAGEMENT @ EDITH COWAN UNIVERSITY',
      description:
        'Heather began her passion for entrepreneurship at a young age, running her first business at the age of nineteen. She recently completed an MBA specialising in Entrepreneurship and Innovation where she was recognised on the School of Business and Law’s 2020 Dean’s List and won the City of Wanneroo, Current Issues in Entrepreneurship and Innovation prize.<br/> <br/> <>Heather is also a passionate advocate for LGBTQIA+ students and young professionals through her role as Chief Community Officer with Out For Australia.',
    },
    {
      id: 15,
      name: 'Glenn Murray',
      imageURL: require('assets/img/faces/MentorGlennMurray.jpg'),
      mutedText: 'Chief Executive and Managing Director @ Sapien Cyber',
      description:
        'Glenn is an accomplished executive with extensive experience in leading multi- million dollar projects in ASX listed companies. Primarily focused in the application of information, communications, technology and cybersecurity solutions across the oil and gas, public, mining, heavy vehicle manufacturing, mining, defence (Electronic Warfare) and telecommunication industries. Glenn has a proven history of thought leadership through presenting at industry events, TEDx, magazine publications, academic publications, news articles and media interviews.<br/> <br/> Glenn’s military background and focus on national security has built a passion for cybersecurity and protecting the world we live in. Glenn embarked on studying a Doctor of Philosophy (PhD) in Cybersecurity at Edith Cowan University and when combined with a focus on strategic design, digital transformation., innovation and organisational effectiveness allows a technology and digitally enabled outcomes. Glenn also holds a Masters in Systems Engineering and a Bachelor in Electrical Engineering with 1st class Honors.',
    },
    //    {
    //      id: 16,
    //      name: 'Dawn Russell',
    //      imageURL: require('assets/img/faces/MentorDawnRussell.jpg'),
    //      mutedText: 'Workforce optimisation @ the heartware group',
    //      description:
    //        '<p>We work with industry leaders who want to be standout employers of choice. They understand the power of a completely aligned workforce and they’re willing to put the time and effort into designing a workplace culture that sets them apart from their competitors. Specifically, we help businesses design and align winning teams by:</p> <br/> <p>✔ mapping out a talent strategy that aligns with and supports the business strategy</p> <br/> <p>✔ profiling employee engagement levels to measure return on investment</p> <br/> <p>✔ embedding a system that’s at the forefront of an approach known as “talent optimisation”</p> <br/> <p>✔ providing education and support on best practice recruitment, onboarding, performance management & professional development.</p>',
    //    },
    // {
    //   id: 17,
    //   name: 'Evelyn Szumski',
    //   imageURL: require('assets/img/faces/MentorEvelyn.JPG'),
    //   mutedText: 'Managing Director @ Leading Training Solutions',
    //   description:
    //     '<p>Evelyn Szumski is Managing Director of Leading Training Solutions, a company offering training in leadership, customer services, business coaching and personal development, specialising in teaching communication and understanding across multi-generational workforces, Emotional Intelligence and overcoming procrastination. Evelyn also specialises in coaching business owners and company CEOs. She helps business owners identify their goals, understand & formalise them & strategise the implementation of achieving these goals in simple, easy to manage steps over allocated time frames and practices brain based coaching being an authorised YB12 coach. Evelyn is also an accredited SEI EQ Assessor. Evelyn is a fun, engaging presenter who has spent more than 20 years in the corporate world developing high performing teams and working with people of any age and background.</p>',
    // },
    // {
    //   id: 18,
    //   name: 'Phil Lee',
    //   imageURL: require('assets/img/faces/MentorPhil.jpg'),
    //   mutedText: 'Acting COO @ Dekko Secure',
    //   description:
    //     '<p>A results-oriented professional, my career spans over 35 years of successful business start-up and management, B2B sales, salesforce recruitment and development, marketing, consultative sales training, leadership development coaching, business consulting, professional speaking, and performance and presentation skills coaching. My focus is helping technology leaders drive sustainable growth. Organisations who have engaged my services include Oracle, Citrix, IBM, Optus and the leaders of many SMEs in the technology space. I’m a high D/I on the DISC profile, meaning my natural behaviour style is goal-oriented, influencing, and people-centric.</p>',
    // },
    // {
    //   id: 19,
    //   name: 'Rael Bricker',
    //   imageURL: require('assets/img/faces/MentorRael.jpg'),
    //   mutedText: 'Consultant @ The Excellence Guy',
    //   description:
    //     '<p>From being 6000ft underground, to starting an education business (that grew to have 4000 plus students) to spending years working in venture capital, Rael has seen it all. He’s listed companies on two international stock exchanges, and his financial services group has settled more than $3bn in loans in 18 years. Rael has diverse work history combined with his unique global research interviews with companies in more than 25 countries. With over 30 years as a serial entrepreneur, Rael Bricker helps businesses succeed by delivering a series of dynamic talks on building businesses by thinking outside the box. Rael has been presenting for many years on business, culture, finance, investing, diversity and ethics. The learning is best practice combined with practical experience to achieve business excellence. Rael Bricker holds two Masters degrees, an MBA and MSc (Software Engineering) and an undergraduate degree in Electrical Engineering (BSc (Eng) . Rael is currently a Fellow of the MFAA. (Mortgage and Finance Association of Australia), a Certified Speaking Professional (CSP) of PSA (Professional Speakers Australia) and a Member of AICD (Australian Institute of Company Directors). Additionally, Rael is the first inspirationalist in Australia with Integrus Global and a Mentor with both Mentored With Mark Bouris and Mastermind.space. Rael is also the Author of “Dive In : Lessons learnt since business school”.</p>',
    // },
    // {
    //   id: 21,
    //   name: 'Scott Blakemore',
    //   imageURL: require('assets/img/faces/MentorScott.jpg'),
    //   mutedText: 'Managing Director @ Blakemore Source',
    //   description:
    //     '<p>Current focus for a range of SME clients include Efficient Inventory management Program and 6s implementation using Lean principles to achieve Operational Excellence- understanding inventory and the supply chain- forecasting and production scheduling to increase profitability in the short term - current clients include building materials, food production, NFP, mining and engineering and medical device companies- the concepts and processes that we have perfected are universal!</p>',
    // },
    // {
    //   id: 22,
    //   name: 'FRAN MORRIS',
    //   imageURL: require('assets/img/faces/MentorFranMorris.jpg'),
    //   mutedText: 'Owner & Facilitator @ INOV8YOU',
    //   description:
    //     '<p>LEGOⓇ Serious Play Facilitator | Program Manager | Executive Coach | Igniting the spark with teams. Anton has Strategic Budgeting and Management experience across a wide range of businesses from retail, import, export and service industries, with an understanding of what is required to keep an organisation functioning from a financial and management point of view. As a strategic and forward-thinking individual who has developed many businesses from the ground up and mentored many others towards success, Anton has created a diverse skill set. Adaption and Innovation are the keys to taking advantage of the opportunities with business development and growth. His philosophy is about transparency and openness with the teams that he works with, and it’s about what brings growth and positive change, not only for the organisation but the team as a whole. His experience in Innovation, negotiations, business development, implementation and formation of business plans, along with dealing with government organisations, and corporations, both large and small, as well as domestically and internationally across a wide range of industry sectors, is seen as a plus.</p>',
    // },
  ],
};
